import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SignupConfirmation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const encodedEmail = searchParams.get('token');

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(`/free-forever-confirmation?token=${encodedEmail}`);
    }, 1000);

    return () => clearTimeout(timer);
  }, [navigate, encodedEmail, location]);

  return (
    <></>
  );
};

export default SignupConfirmation;
