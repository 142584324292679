import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PlanConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectedToCurrent, setRedirectedToCurrent] = useState(false);

  useEffect(() => {
    const dataLayerTimer = setTimeout(() => {
      if (!redirectedToCurrent) {
        navigate(location.pathname);
        setRedirectedToCurrent(true);
      }
    }, 3000);

    const navigationTimer = setTimeout(() => {
      navigate(`/settings/billing`);
    }, 4000);

    return () => {
      clearTimeout(dataLayerTimer);
      clearTimeout(navigationTimer);
    };

  }, [navigate, location, redirectedToCurrent]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default PlanConfirmation;
