import React, { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetWorkSpaceMutation, useStatusInvitationMutation, useGetInvitationMutation } from 'src/services/workSpace-service'

function Invitation () {
  const [name, setName] = useState({})
  const [workSpaceName, setWorkspaceName] = useState('')
  const [invitationStatus, setInvitationStatus] = useState('')
  const [existingUser, setExistingUser] = useState(false)
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const id = searchParams.get('id')
  const token = searchParams.get('token')
  const [getWorkSpace] = useGetWorkSpaceMutation()
  const [statusInvitation] = useStatusInvitationMutation()
  const [getInvitation] = useGetInvitationMutation()
  const navigate = useNavigate()
  const getSingleWorkspace = async () => {
    const { data } = await getWorkSpace({ id, email })
    setName(data?.name)
    setWorkspaceName(data?.workspaceName)
    if (data?.existingUser) {
      setExistingUser(true)
    }
  }

  const getCurrentInvitation = async () => {
    const { data } = await getInvitation(token)
    setInvitationStatus(data?.status)
  }

  const handleResponse = async (isAccepted) => {
    try {
      if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        const authToken = window.localStorage.getItem('auth_token')
        if (authToken && isAccepted) {
          window.localStorage.removeItem('auth_token')
        }
      }
      const { data } = await statusInvitation({
        id,
        token,
        status: isAccepted ? 'accepted' : 'declined'
      })
      setInvitationStatus(data?.status)

      if (data?.status === 'accepted' && existingUser) {
        navigate('/login')
      } else if (data?.status === 'accepted' && !existingUser) {
        navigate(`/register?email=${email}`)
      }
    } catch (error) {
      console.error('Error handling invitation response:', error)
    }
  }

  const handleAccept = () => {
    handleResponse(true)
  }

  const handleDecline = () => {
    handleResponse(false)
  }

  useEffect(() => {
    if (id) {
      getSingleWorkspace()
    }
  }, [id])

  useEffect(() => {
    if (token) {
      getCurrentInvitation()
    }
  }, [token])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        bgcolor: 'background.paper'
      }}
    >
      <Typography variant='h4' color='primary' gutterBottom>
        Success.ai
      </Typography>
      <Typography variant='body1' sx={{ mt: 2 }}>
        <b>{name?.first && name?.last ? `${name?.first} ${name?.last}` : 'Super Admin'}</b> invited you to join <b>{workSpaceName || 'My Organization'}</b>
      </Typography>
      <Box sx={{ mt: 4 }}>
        {!invitationStatus && (
          <Typography variant='body2'>
            Invitation Declined
          </Typography>
        )}
        {(invitationStatus === 'accepted' || invitationStatus === 'pending') && (
          <>
            <Button variant='outlined' color='primary' sx={{ mr: 2 }} onClick={handleAccept}>
              {existingUser ? 'Accept' : 'Accept & Create Account'}
            </Button>
            <Button variant='outlined' color='error' onClick={handleDecline}>
              Decline
            </Button>
          </>
        )}
      </Box>
    </Box>
  )
}

export default Invitation
