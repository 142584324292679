import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  FormControl,
  FormHelperText,
  Autocomplete,
  Chip,
  InputAdornment,
  useMediaQuery
  , alpha
} from '@mui/material'
import { RocketLaunch, SaveAltOutlined, WarningRounded } from '@mui/icons-material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { toast } from 'react-hot-toast'
import CustomCheckbox from '../CustomCheckbox'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'
import { SaveIconBlue } from 'src/assets/general/SaveIcon'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { FireIcon } from 'src/assets/general/FireIcon'
// import OutlinedInput from '@mui/material/OutlinedInput'
import ListItemText from '@mui/material/ListItemText'
import {
  useGetAccountsMutation,
  accountsAdded,
  setAccounts
} from 'src/services/account-service.js'
import {
  useUpdateConfigurationsMutation,
  useCampaignLaunchMutation,
  useGetCampaignQuery,
  useGetSingleCampaignMutation
} from 'src/services/campaign-service.js'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useUpdateIntercomMutation } from 'src/services/intercom-service'
import { useGetMeQuery } from 'src/services/user-service'
import { AiOutlineClose } from 'react-icons/ai'
import { FaTags } from 'react-icons/fa'

const scrollBarStyle = {
  // width
  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
    borderLeft: '1px solid #E4E4E5',
    backgroundColor: '#F2F4F6'
  },

  // Track
  '&::-webkit-scrollbar-track': {
    borderRadius: '60px'
  },

  // /* Handle */
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#E4E4E5',
    borderRadius: '10px',
    border: '2px solid rgba(0, 0, 0, 0)',
    backgroundClip: 'padding-box'
  },

  // /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#d5d5d5'
  }
}

const tabData = [
  { label: 'Accounts' },
  { label: 'Reply Stops' },
  { label: 'Tracking' },
  { label: 'Limit' },
  { label: 'Optimized Sending', sx: { minWidth: '165px' } }
]

const CampaignOptions = ({ campaign, canEdit }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  // const { options } = campaign;

  const [valueTabs, setValueTabs] = React.useState(0)
  const [emails, setEmails] = useState([])
  const [options, setOptions] = useState(campaign.options)
  const [getData, setGetData] = useState(false)

  const [getSingleCampaign] = useGetSingleCampaignMutation()
  const fetchData = async () => {
    try {
      const campaignData = await getSingleCampaign(campaign._id)
      const optionsData = campaignData?.data?.options
      setOptions(optionsData)
      setEmails(optionsData?.emailAccounts)
      formik.setValues({
        ...formik.values,
        emailAccounts: optionsData?.emailAccounts || []
      })
    } catch (error) {
      console.error('Error fetching campaign data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [getData])

  const dispatch = useDispatch()
  const [workspaceId, setWorkspaceId] = useState()
  const workspace = useSelector((state) => state.workspace)
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  const emailAccounts = []
  const accounts = useSelector((state) => state.accounts)
  accounts?.forEach((element) => {
    emailAccounts.push(element.email)
  })

  const listOption = []
  const tagToEmailsMap = {}
  const addedTags = new Set()

  accounts.forEach((account) => {
    const email = account.email
    const accountTags = account.tags
    listOption.push({ type: 'email', key: email })
    if (accountTags) {
      accountTags.forEach((tag) => {
        if (!addedTags.has(tag.label)) {
          listOption.push({ type: 'tag', key: tag.label })
          addedTags.add(tag.label)
        }
        if (!tagToEmailsMap[tag.label]) {
          tagToEmailsMap[tag.label] = []
        }
        tagToEmailsMap[tag.label].push(email)
      })
    }
  })
  const sortedListOption = listOption?.sort((a, b) => {
    if (a.type === 'tag' && b.type !== 'tag') return -1;
    if (a.type !== 'tag' && b.type === 'tag') return 1;
    return 0;
  });
  
  useEffect(() => {
    if (options?.emailAccounts?.length) {
      setEmails(options?.emailAccounts)
    }
  }, [options])

  const handleFieldSelectionAndValidation = (event, node) => {
    const newValues = node
    const currentEmail = event?.target?.innerText

    setEmails(newValues)
    const objectFound = accounts.find((obj) => obj?.email === currentEmail)
    if (objectFound?.status === 'reconnect') {
      if (!newValues.includes(currentEmail)) {
        formik.setFieldValue('emailAccounts', newValues)
      } else {
        toast.error('This account is on reconnect, please reconnect it to use it in the campaign')
      }
    } else {
      formik.setFieldValue('emailAccounts', newValues)

      if (objectFound?.status === 'paused' && newValues.includes(currentEmail)) {
        toast.error('This account is inactive, please active it to use it in the campaign')
      }
    }
  }
  const [getAccounts, { isLoading: isAccountsLoading }] = useGetAccountsMutation()

  const { refetch: refetchCampaign } = useGetCampaignQuery(campaign._id)

  // Search, filter and pagination
  const [isLoadingMoreAccounts, setIsLoadingMoreAccounts] = useState(false)
  const [search] = useState('')
  const [filter] = useState(null)
  const [total, setTotal] = useState(0)
  const [launch, setLaunch] = useState(false)
  const offset = accounts.length
  const limit = 15
  const [updateIntercom] = useUpdateIntercomMutation()
  const { data: user } = useGetMeQuery()

  // max leads limit dialog
  const [highdailyMaxLimitDialogOpen, setHighdailyMaxLimitDialogOpen] = useState(false)
  const [boundHighdailyMaxLimitDialogOpen, setBoundHighdailyMaxLimitDialogOpen] = useState(false)
  const [isHighLimitOkay, setIsHighLimitOkay] = useState(false)
  const [notChecked, setNotChecked] = useState(false)

  const highdailyMaxLimitDialogClose = () => {
    setHighdailyMaxLimitDialogOpen(false)
  }
  const highdailyMaxLimitBoundDialogClose = () => {
    setBoundHighdailyMaxLimitDialogOpen(false)
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      const { docs, total } = await getAccounts({
        search,
        filter: filter?.value,
        unibox: true,
        option: true,
        workspaceId
      }).unwrap()
      dispatch(setAccounts(docs))
      setTotal(total)
    }, 500)
    return () => clearTimeout(timer)
  }, [search, filter, limit, getAccounts, dispatch, workspaceId])

  useEffect(() => {
    const handler = async () => {
      if (isLoadingMoreAccounts) return
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement

      if (scrollHeight - scrollTop === clientHeight && offset < total) {
        setIsLoadingMoreAccounts(true)
        const { docs, total } = await getAccounts({
          search,
          filter: filter?.value,
          offset,
          limit,
          workspaceId
        }).unwrap()
        dispatch(accountsAdded(docs))
        setTotal(total)
        setIsLoadingMoreAccounts(false)
      }
    }

    window.addEventListener('scroll', handler)
    return () => window.removeEventListener('scroll', handler)
  }, [
    isLoadingMoreAccounts,
    search,
    filter,
    total,
    offset,
    limit,
    getAccounts,
    dispatch,
    workspaceId
  ])

  const [UpdateConfigurations] = useUpdateConfigurationsMutation()
  const [initialValuesFormik] = useState({
    emailAccounts: options?.emailAccounts,
    dailyMaxLimit: options?.dailyMaxLimit,
    emailDelay: options?.emailDelay,
    randomDelay: options?.randomDelay,
    prioritizeLeads: options?.prioritizeLeads,
    // dailyMaxLeadsLimit: options?.dailyMaxLeadsLimit,
    stopOnReply: options?.stopOnReply,
    stopOnAutoReply: options?.stopOnAutoReply,
    trackOpen: options?.trackOpen,
    trackClickedLink: options?.trackClickedLink,
    textOnly: options?.textOnly
  })

  const formik = useFormik({
    initialValues: initialValuesFormik,
    validationSchema: Yup.object({
      // emailAccounts: Yup.array()
      //   .min(1, "At least one email account is required")
      //   .of(Yup.string().required("Email account is required")),
      dailyMaxLimit: Yup.string()
        ?.matches(/^[0-9]+$/, 'Please enter numerical digits only')
        .required('Daily Max Limit is required'),
      emailDelay: Yup.number()
        .typeError('Please enter numerical digits only')
        .min(3, 'Value must be at least 3')
        .max(1000, 'Value must be at most 1000')
        .required('Time gap is required'),
      randomDelay: Yup.number()
        .typeError('Please enter numerical digits only')
        .min(0, 'Value must be at least 0')
        .max(1000, 'Value must be at most 1000')
        .required('Random time is required')
        .test('is-integer', 'Please enter a whole number.', (value) => Number.isInteger(value))
      // dailyMaxLeadsLimit: Yup.string()
      //   .matches(/^[0-9]+$/, "Please enter numerical digits only")
      //   .required("Daily Max Leads Limit is required"),
    }),
    onSubmit: async (values) => {
      try {
        if (values?.emailAccounts?.length === 0) {
          toast.error('At least one email account is required')
          return
        } 

        if (values.dailyMaxLimit === '') {
          formik.setFieldValue('dailyMaxLimit', '20')
        }
        if (values.emailDelay === '') {
          formik.setFieldValue('emailDelay', 3)
        }
        if (
          values?.dailyMaxLimit > values?.emailAccounts.length * 50 &&
          values?.dailyMaxLimit <= 5000 &&
          !isHighLimitOkay
        ) {
          setHighdailyMaxLimitDialogOpen(true)
          return
        }
        if (values?.dailyMaxLimit > 5000 && !isHighLimitOkay) {
          setBoundHighdailyMaxLimitDialogOpen(true)
          formik.setFieldValue('dailyMaxLimit', '20')
          return
        }
        setGetData(true)
        setHighdailyMaxLimitDialogOpen(false)
        setBoundHighdailyMaxLimitDialogOpen(false)
        setIsHighLimitOkay(false)
        const { message } = await UpdateConfigurations({
          campaignID: campaign._id,
          options: values
        }).unwrap()
        toast.success(message)
        setGetData(false)
        await refetchCampaign()
      } catch (err) {
        toast.error(err.data.error.message)
      }
    }
  })

  const roundedValue = Math.ceil(formik?.values?.dailyMaxLimit / 50)
  const inactiveAccounts = formik?.values?.emailAccounts.filter((email) => {
    const foundObject = accounts?.find((obj) => obj.email === email)
    return foundObject?.status === 'paused' || foundObject?.status === 'reconnect'
  })

  const [campaignLaunch, { isLoading: isSendingCampaign }] = useCampaignLaunchMutation()
  const [prioritizeLeads] = useState(false)

  const launchCampaign = async (formik) => {
    try {
      setLaunch(true)
      if (formik.values.emailAccounts.length < 1) {
        toast.error('Please Add email account and save campaign first')
        return
      }

      if (formik.values.dailyMaxLimit < 1) {
        toast.error('Daily limit must be greater than 200')
        return
      }
      if (formik.values.dailyMaxLimit > 5000) {
        toast.error('The daily limit must not exceed 5000.')
        return
      }

      const selectedAccountsObject = []

      formik.values.emailAccounts.forEach((emailOrTag) => {
        if (tagToEmailsMap[emailOrTag]) {
          tagToEmailsMap[emailOrTag].forEach((email) => {
            const account = accounts?.find((obj) => obj.email === email)
            if (account) {
              selectedAccountsObject.push(account)
            }
          })
        } else {
          const account = accounts?.find((obj) => obj.email === emailOrTag)
          if (account) {
            selectedAccountsObject.push(account)
          }
        }
      })

      const isAllAccountsPaused = selectedAccountsObject.every((acc) => acc.status === 'paused')
      if (isAllAccountsPaused) {
        toast.error(
          'All selected accounts are inactive, please activate them to use in the campaign'
        )
        return
      }
      const { message } = await campaignLaunch({ id: campaign._id }).unwrap()
      await refetchCampaign()
      toast.success(message)
      await updateIntercom({ user: user._id, attribute: 'campaigns_launched' })
      window.Intercom('trackEvent', 'Campaign launched')
      navigate('/campaigns')
    } catch (err) {
      toast.error(err?.data?.error?.message)
    }
  }

  const isAnySelectedAccountPaused = (array) => {
    const selectedAccountPaused = array.map((email) => {
      const foundObject = accounts?.find((obj) => obj.email === email)
      return foundObject?.status === 'paused' || foundObject?.status === 'reconnect'
    })
    return selectedAccountPaused.includes(true)
  }

  const isSelectedAccountPausedByEmail = (email) => {
    const foundObject = accounts?.find((obj) => obj.email === email)
    return foundObject?.status === 'paused' || foundObject?.status === 'reconnect'
  }

  const handleCloseEmail = (email) => {
    const index = emails.indexOf(email)
    if (index !== -1) {
      const newEmails = [...emails]
      newEmails.splice(index, 1)
      formik.setFieldValue('emailAccounts', newEmails)
      setEmails(newEmails)
    }
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isTab = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const inputStyles = {
    width: '100%',
    backgroundColor: 'white',
    '& div': { pl: 0.3 },
    '& div fieldset': {
      borderRadius: '8px',
      border: '1px solid #E4E4E5'
    },
    '& div input': {
      py: 1.3,
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '16px',
      '&::placeholder': {
        color: 'rgba(40, 40, 123, 0.5)'
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield'
      }
    }
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        {' '}
        <form noValidate onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          {' '}
          <Box
            sx={{
              boxShadow: '0px 12px 15px 0px #4B71970D',
              borderRadius: '12px',
              // pb: 5,
              // px: 4,
              // pt: 1,
              backgroundColor: 'white',
              width: '100%',
              p: 3
            }}
          >
            <Tabs
              value={valueTabs}
              onChange={(e, value) => setValueTabs(value)}
              aria-label='basic tabs example'
              variant='fullWidth'
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
              sx={{
                backgroundColor: '#F2F4F6',
                width: '100%',
                borderRadius: '8px',

                border: '1px solid #F2F4F7',
                '& .MuiTabs-flexContainer': {
                  overflowX: 'auto',
                  '&::-webkit-scrollbar': {
                    display: 'none'
                  }
                },
                '& .MuiTab-root': {
                  m: '5px',
                  p: 0,
                  minHeight: '38px',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  letterSpacing: '0em',

                  borderRadius: '5px'
                },
                '& .MuiTab-root.Mui-selected': {
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow: '0px 1px 2px 0px #1018280F'
                }
              }}
            >
              {tabData.map((tab, index) => (
                <Tab key={index} label={tab?.label} sx={tab?.sx} />
              ))}
            </Tabs>
            <Box sx={{ mt: 3 }}>
              {valueTabs === 0
                ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 4
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '0em',
                            color: '#28287B'
                          }}
                        >
                          Accounts To Use
                        </Typography>{' '}
                        <Typography
                          sx={{
                            fontSize: '13px',
                            fontWeight: 400,
                            lineHeight: '22px',
                            letterSpacing: '0em',
                            color: '#8181B0'
                          }}
                        >
                          Select One Or More Accounts To Send Emails From
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%'
                        }}
                      >
                        <FormControl
                          sx={{ width: '100%' }}
                          error={!!(formik.touched.emailAccounts && formik.errors.emailAccounts)}
                        >
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            id='email-accounts-autocomplete'
                            options={sortedListOption}
                            getOptionLabel={(option) => option?.key}
                            value={formik?.values?.emailAccounts?.map((val) => {
                              return sortedListOption.find(
                                (option) =>
                                  option?.key?.replace(/\s/g, '').toLowerCase() ===
                                  val?.replace(/\s/g, '').toLowerCase()
                              )
                            })}
                            onChange={(event, value) => {
                              const selectedKeys = value?.map((v) => v?.key)
                              handleFieldSelectionAndValidation()
                              const selectedEmailsSet = new Set()
                              const selectedTags = new Set()

                              selectedKeys.forEach((key) => {
                                if (tagToEmailsMap[key]) {
                                  tagToEmailsMap[key].forEach((email) => {
                                    selectedEmailsSet.add(email)
                                    handleFieldSelectionAndValidation()
                                  })
                                  selectedTags.add(key)
                                } else {
                                  selectedEmailsSet.add(key)
                                }
                              })

                              formik?.values?.emailAccounts?.forEach((val) => {
                                const existingOption = sortedListOption.find(
                                  (option) =>
                                    option?.key?.replace(/\s/g, '').toLowerCase() ===
                                    val?.replace(/\s/g, '').toLowerCase()
                                )
                                if (
                                  existingOption &&
                                  tagToEmailsMap[existingOption.key] &&
                                  !selectedTags.has(existingOption.key)
                                ) {
                                  tagToEmailsMap[existingOption?.key].forEach((email) => {
                                    selectedEmailsSet.delete(email)
                                  })
                                  selectedEmailsSet.delete(existingOption.key)
                                }
                              })

                              const emailArray = Array.from(selectedEmailsSet).filter(Boolean)
                              const tagArray = Array.from(selectedTags).filter(Boolean)

                              handleFieldSelectionAndValidation(event, emailArray)
                              formik.setFieldValue('emailAccounts', [
                                ...new Set([...emailArray, ...tagArray])
                              ])
                              handleCloseEmail()
                            }}
                            onBlur={formik.handleBlur('emailAccounts')}
                            loading={isAccountsLoading}
                            loadingText={
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <CircularProgress size={20} sx={{ mr: 1 }} />
                                <Typography sx={{ color: '#0071F6' }}>Loading...</Typography>
                              </Box>
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='outlined'
                                label='Search and select emails'
                              />
                            )}
                            renderOption={(props, option, { selected }) => {
                              const selectedAccountPaused = isSelectedAccountPausedByEmail(
                                option?.key
                              )
                              const isChecked =
                                selected ||
                                // tagToEmailsMap[option.key]?.some((email) =>
                                //   formik.values.emailAccounts.includes(email)
                                // ) ||
                                formik?.values?.emailAccounts?.includes(option?.key)
                              return (
                                <Box sx={{ display: 'flex', alignItems: 'center' }} {...props}>
                                  <Checkbox checked={isChecked} />
                                  {option?.type === 'tag' && (
                                    <FaTags style={{ marginRight: '6px' }} fontSize='small' />
                                  )}
                                  <ListItemText primary={option?.key} />
                                  {selectedAccountPaused ? '(Inactive)' : null}
                                </Box>
                              )
                            }}
                            renderValue={(selected) => {
                              const selectedAccountPaused = isAnySelectedAccountPaused(selected)
                              return (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  <Typography
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column'
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        gap: 1,
                                        marginBottom: 1,
                                        flexWrap: 'wrap'
                                      }}
                                    />
                                    {selectedAccountPaused
                                      ? (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                          <ErrorOutlineIcon
                                            sx={{ fontSize: '1rem', color: 'red', marginRight: 1 }}
                                          />
                                          <p
                                            style={{ margin: '0', fontSize: '.8rem', fontWeight: 500 }}
                                          >
                                            Account not active
                                          </p>
                                        </Box>
                                        )
                                      : null}
                                  </Typography>
                                </Box>
                              )
                            }}
                            renderTags={() => null}
                            IconComponent={ExpandMoreIcon}
                          />

                          {formik.touched.emailAccounts && formik.errors.emailAccounts && (
                            <FormHelperText
                              sx={{ margin: 0, position: 'absolute', bottom: '-24px' }}
                              error
                            >
                              {formik.errors.emailAccounts}
                            </FormHelperText>
                          )}
                        </FormControl>
                        {/* x inactive accounts */}
                        {inactiveAccounts.length > 0 && (
                          <p style={{ fontSize: '.8rem', color: 'red', marginTop: '.5rem' }}>
                            {inactiveAccounts.length} inactive
                          </p>
                        )}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        pt: 3,
                        justifyContent: 'start',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#28287B'
                        }}
                      >
                        Total Selected Accounts :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '22px',
                          letterSpacing: '0em',
                          color: '#8181B0'
                        }}
                      >
                        {emails
                        ?.filter((email) => email?.includes('@'))?.length}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      spacing={2}
                      sx={{ maxHeight: '410px', overflow: 'auto', mt: '8px', ...scrollBarStyle }}
                    >
                      {emails
                        ?.filter((email) => email?.includes('@'))
                        .map((email, index) => {
                          const isInactive = inactiveAccounts.includes(email)
                          return (
                            <Grid item key={index} sx={{ display: 'flex', mb: 1 }}>
                              <Chip
                                label={email}
                                onDelete={() => handleCloseEmail(email)}
                                deleteIcon={
                                  <AiOutlineClose
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '14px'
                                    }}
                                  />
                                }
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 700,
                                  lineHeight: '16.38px',
                                  height: '40px',
                                  px: 2,
                                  color: isInactive ? 'red' : theme.palette.primary.main,
                                  backgroundColor: isInactive
                                    ? 'lightgray'
                                    : alpha(theme.palette.primary.main, 0.1),
                                  '& .MuiChip-deleteIcon': {
                                    color: isInactive ? 'red' : theme.palette.primary.main,
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                      backgroundColor: 'transparent'
                                    }
                                  },
                                  '&:hover': {
                                    backgroundColor: isInactive
                                      ? 'lightgray'
                                      : alpha(theme.palette.primary.main, 0.2)
                                  }
                                }}
                              />
                            </Grid>
                          )
                        })}
                    </Grid>
                  </>
                  )
                : valueTabs === 1
                  ? (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '0em',
                              color: '#28287B'
                            }}
                          >
                            Stop Emails To A Lead After They Reply.
                          </Typography>{' '}
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '22px',
                              letterSpacing: '0em',
                              color: '#8181B0'
                            }}
                          >
                            Don't Email A Lead Once They've Responded.
                          </Typography>
                        </Box>{' '}
                        <CustomCheckbox
                          value={formik.values.stopOnReply}
                          onChange={(_, newValue) => {
                            formik.setFieldValue('stopOnReply', newValue)
                          }}
                          checked={formik.values.stopOnReply}
                        />
                      </Box>

                      <FormGroup sx={{ width: '100%', justifyContent: 'flex-start', mt: 1 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={formik.values.stopOnAutoReply}
                              onChange={(_, newValue) => {
                                formik.setFieldValue('stopOnAutoReply', newValue)
                              }}
                              checked={formik.values.stopOnAutoReply}
                              icon={<OffCheckboxCustomIcon />}
                              checkedIcon={<OnCheckboxCustomIcon />}
                            />
                          }
                          label='Pause on Auto-Reply'
                          sx={{
                            '& span': {
                              fontSize: '13px',
                              fontWeight: 500,
                              lineHeight: '16px',
                              letterSpacing: '0em',
                              color: '#28287B'
                            }
                          }}
                        />
                      </FormGroup>
                    </>
                    )
                  : valueTabs === 2
                    ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '0em',
                              color: '#28287B'
                            }}
                          >
                            Tracking
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'flex-start',
                            alignItems: { xs: 'flex-start', sm: 'center' },
                            width: '100%',
                            mt: 1
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: { xs: 'space-between', sm: 'center' },
                              alignItems: 'center',
                              width: { xs: '100%', sm: 'fit-content' },
                              mr: { xs: 0, sm: 3 }
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                letterSpacing: '0em',
                                color: '#8181B0',
                                mr: 1.5
                              }}
                            >
                              Track Opened Emails
                            </Typography>
                            <CustomCheckbox
                              disabled={formik.values.textOnly}
                              value={formik.values.trackOpen}
                              onChange={(_, newValue) => {
                                formik.setFieldValue('trackOpen', newValue)
                              }}
                              checked={!formik.values.textOnly && formik.values.trackOpen}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: { xs: 'space-between', sm: 'center' },
                              alignItems: 'center',
                              width: { xs: '100%', sm: 'fit-content' }
                            }}
                          >
                            {' '}
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                letterSpacing: '0em',
                                color: '#8181B0',
                                mr: 1.5
                              }}
                            >
                              Track Clicked Links
                            </Typography>
                            <CustomCheckbox
                              value={formik.values.trackClickedLink}
                              onChange={(_, newValue) => {
                                formik.setFieldValue('trackClickedLink', newValue)
                              }}
                              checked={formik.values.trackClickedLink}
                              handle
                            />
                          </Box>
                        </Box>
                      </>
                      )
                    : valueTabs === 3
                      ? (
                        <>
                          {' '}
                          <Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%'
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flexDirection: 'column'
                                }}
                              >
                                {' '}
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '20px',
                                    letterSpacing: '0em',
                                    color: '#28287B'
                                  }}
                                >
                                  Daily Max Emails Sent Limit
                                </Typography>{' '}
                              </Box>{' '}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  lineHeight: '22px',
                                  letterSpacing: '0em',
                                  color: '#8181B0',
                                  mr: 1.5,
                                  width: '50%'
                                }}
                              >
                                Maximum daily emails total number for this campaign.
                              </Typography>
                              <TextField
                                variant='outlined'
                                name='dailyMaxLimit'
                                value={formik.values.dailyMaxLimit}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.dailyMaxLimit && Boolean(formik.errors.dailyMaxLimit)}
                                helperText={formik.touched.dailyMaxLimit && formik.errors.dailyMaxLimit}
                                sx={{
                                  width: isMobile || isTab ? '40%' : '24%',
                                  backgroundColor: 'white',
                                  '& div': { pl: 0.3 },
                                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                                  '& div input': {
                                    py: 1.3,
                                    fontSize: '13px',
                                    fontWeight: 400,
                                    lineHeight: '16px',
                                    letterSpacing: '0em',
                                    '&::placeholder': {
                                      color: 'rgba(40, 40, 123, 0.5)'
                                    }
                                  }
                                  // boxShadow: 10,
                                }}
                              />
                            </Box>
                          </Box>
                          <Box sx={{ marginTop: 2 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                flexDirection: 'column'
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flexDirection: 'column',
                                  width: '100%',
                                  mb: 2
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '20px',
                                    color: '#28287B'
                                  }}
                                >
                                  Time Gap Between Emails
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    fontWeight: 400,
                                    lineHeight: '22px',
                                    letterSpacing: '0em',
                                    color: '#8181B0',
                                    mt: 0.5,
                                    width: { xs: '60%', sm: '75%' }
                                  }}
                                >
                                  Time gap between emails for this campaign in minutes.
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  width: '100%'
                                }}
                              >
                                <Box sx={{ mr: 2 }}>
                                  <Typography
                                    sx={{
                                      fontSize: '13px',
                                      fontWeight: 400,
                                      lineHeight: '22px',
                                      color: '#8181B0'
                                    }}
                                  >
                                    Minimum time
                                  </Typography>
                                  <TextField
                                    variant='outlined'
                                    name='emailDelay'
                                    type='number'
                                    value={formik.values.emailDelay}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.emailDelay && Boolean(formik.errors.emailDelay)}
                                    helperText={formik.touched.emailDelay && formik.errors.emailDelay}
                                    InputProps={{
                                      inputProps: { min: 3, max: 1000 },
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          <span style={{ fontSize: '0.75rem' }}>minutes</span>
                                        </InputAdornment>
                                      )
                                    }}
                                    sx={inputStyles}
                                  />
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                  <Typography
                                    sx={{
                                      fontSize: '13px',
                                      fontWeight: 400,
                                      lineHeight: '22px',
                                      color: '#8181B0'
                                    }}
                                  >
                                    Random additional time
                                  </Typography>
                                  <TextField
                                    variant='outlined'
                                    name='randomDelay'
                                    type='number'
                                    value={formik.values.randomDelay}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.randomDelay && Boolean(formik.errors.randomDelay)}
                                    helperText={formik.touched.randomDelay && formik.errors.randomDelay}
                                    InputProps={{
                                      inputProps: { min: 0, max: 1000 },
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          <span style={{ fontSize: '0.75rem' }}>minutes</span>
                                        </InputAdornment>
                                      )
                                    }}
                                    sx={inputStyles}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                        )
                      : valueTabs === 4
                        ? (
                          <>
                            {' '}
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '100%'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  lineHeight: '20px',
                                  letterSpacing: '0em',
                                  color: '#28287B'
                                }}
                              >
                                Optimized Sending
                              </Typography>
                            </Box>
                            <FormGroup sx={{ width: '100%', justifyContent: 'flex-start', mt: 2 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={formik.values.trackOpen}
                                    value={formik.values.textOnly}
                                    onChange={(_, newValue) => {
                                      formik.setFieldValue('textOnly', newValue)
                                    }}
                                    checked={!formik.values.trackOpen && formik.values.textOnly}
                                    icon={<OffCheckboxCustomIcon />}
                                    checkedIcon={<OnCheckboxCustomIcon />}
                                  />
                                }
                                label='Send as plain text, no HTML.'
                                sx={{
                                  '& span': {
                                    fontSize: '13px',
                                    fontWeight: 400,
                                    lineHeight: '22px',
                                    letterSpacing: '0em',
                                    color: '#8181B0'
                                  }
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 400,
                                  lineHeight: '22px',
                                  letterSpacing: '0em',
                                  color: '#8181B0'
                                }}
                              >
                                {formik.values.trackOpen
                                  ? 'Disable "Track Opened Emails" in Tracking to enable "Optimized Sending"'
                                  : 'Enabling "Optimized Sending" will disable "Track Opened Emails" in Tracking'}
                              </Typography>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={formik.values.prioritizeLeads}
                                    checked={formik.values.prioritizeLeads}
                                    onChange={(_, newValue) => {
                                      formik.setFieldValue('prioritizeLeads', newValue)
                                    }}
                                    icon={<OffCheckboxCustomIcon />}
                                    checkedIcon={<OnCheckboxCustomIcon />}
                                  />
                                }
                                label='Prioritize the contacted leads'
                                sx={{
                                  '& span': {
                                    fontSize: '13px',
                                    fontWeight: 400,
                                    lineHeight: '22px',
                                    letterSpacing: '0em',
                                    color: '#8181B0'
                                  }
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 400,
                                  lineHeight: '22px',
                                  letterSpacing: '0em',
                                  color: '#8181B0'
                                }}
                              >
                                {prioritizeLeads
                                  ? 'You have prioritized the contacted leads.'
                                  : 'Prioritizing the contacted leads can improve response rates.'}
                              </Typography>
                            </FormGroup>
                          </>
                          )
                        : null}
            </Box>
          </Box>
          {canEdit && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                mt: 0,
                py: 3
              }}
            >
              <Button
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': {
                    boxShadow: 10
                  },
                  textAlign: 'left',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  color: '#0071F6',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  px: 1.5,
                  py: 1.5,
                  border: '1px solid #0071F6'
                }}
                variant='outlined'
                type='submit'
              >
                {formik.isSubmitting && !launch
                  ? (
                    <CircularProgress size={20} color='inherit' />
                    )
                  : (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mr: 1
                        }}
                      >
                        <SaveIconBlue />
                      </Box>
                      Save
                    </>
                    )}
              </Button>
              <Button
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor: '#164694',
                    boxShadow: 10
                  },
                  textAlign: 'left',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  color: 'white',
                  backgroundColor: '#0071F6',
                  borderRadius: '8px',
                  px: 1.5,
                  py: 1.5,
                  ml: 3
                }}
                variant='contained'
                onClick={() => launchCampaign(formik)}
              >
                {isSendingCampaign
                  ? (
                    <CircularProgress size={20} color='inherit' />
                    )
                  : (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mr: 1
                        }}
                      >
                        <FireIcon />
                      </Box>
                      Launch
                    </>
                    )}
              </Button>
            </Box>
          )}
        </form>
      </Box>

      {/* Old design */}
      <Box
        sx={{
          // display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid rgba(0,0,0,0.2)',
          pb: 7,
          mt: 20,
          display: 'none'
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {' '}
            <Box
              sx={{
                boxShadow: '0px 0px 12px -1px rgba(0, 0, 0, 0.25)',
                borderRadius: 1,
                p: 3,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                mt: 2
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgb(33, 111, 237)",
                    borderRadius: "20px",
                    p: 1,
                  }}
                >
                  <StopCircleOutlined sx={{ color: "white" }} />
                </Box> */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                  }}
                >
                  {' '}
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      letterSpacing: '0em',
                      color: '#28287B'
                    }}
                  >
                    Stop sending emails on reply
                  </Typography>{' '}
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 400,
                      lineHeight: '22px',
                      letterSpacing: '0em',
                      color: '#8181B0'
                    }}
                  >
                    Stop sending emails to a lead if a response has been received
                  </Typography>
                </Box>{' '}
                <CustomCheckbox checked />
              </Box>

              <FormGroup sx={{ width: '100%', justifyContent: 'flex-start', mt: 1 }}>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label='Stop on auto reply'
                  sx={{ '& span': { fontSize: '13px' } }}
                />
              </FormGroup>
            </Box>
            <Box
              sx={{
                boxShadow: '0px 0px 12px -1px rgba(0, 0, 0, 0.25)',
                borderRadius: 1,
                p: 3,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                mt: 2
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgb(33, 111, 237)",
                    borderRadius: "20px",
                    p: 1,
                  }}
                >
                  <PublishOutlined sx={{ color: "white" }} />
                </Box> */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                  }}
                >
                  {' '}
                  <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Limit</Typography>{' '}
                  <Typography
                    sx={{
                      fontSize: '14px',
                      textAlign: 'left',
                      width: '100%',
                      color: 'rgba(0,0,0,0.4)'
                    }}
                  >
                    Max number of emails to send per day for this campaign
                  </Typography>
                </Box>{' '}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                    // width: "100%",
                  }}
                >
                  {' '}
                  <TextField
                    defaultValue={20}
                    variant='outlined'
                    size='small'
                    sx={{ width: '100px' }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                boxShadow: '0px 0px 12px -1px rgba(0, 0, 0, 0.25)',
                borderRadius: 1,
                p: 3,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                mt: 2
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgb(33, 111, 237)",
                    borderRadius: "20px",
                    p: 1,
                  }}
                >
                  <ShareLocationOutlined sx={{ color: "white" }} />
                </Box> */}

                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Tracking</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                  }}
                >
                  {' '}
                  <Typography
                    sx={{
                      my: 1,
                      fontSize: '14px',
                      textAlign: 'left',
                      width: '100%',
                      color: 'rgba(0,0,0,0.4)'
                    }}
                  >
                    Track email opens
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <CustomCheckbox checked />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                  }}
                >
                  {' '}
                  <Typography
                    sx={{
                      my: 1,
                      fontSize: '14px',
                      textAlign: 'left',
                      width: '100%',
                      color: 'rgba(0,0,0,0.4)'
                    }}
                  >
                    Track link clicks
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <CustomCheckbox checked={false} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Dialog
              open={highdailyMaxLimitDialogOpen}
              onClose={highdailyMaxLimitDialogClose}
              maxWidth='xs'
              fullWidth
            >
              <DialogTitle
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  color: '#28287B',
                  p: 4
                }}
              >
                Large daily limit
              </DialogTitle>
              <DialogContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  gap: 2,
                  p: 4
                }}
              >
                <Typography sx={{ color: theme.palette.error.main }}>
                  In campaigns if your daily limit is more than {formik?.values?.dailyMaxLimit} ,
                  you should use atleast {roundedValue} sender accounts, instead of using{' '}
                  {formik?.values?.emailAccounts?.length}, sending that many emails from{' '}
                  {formik?.values?.emailAccounts?.length} accounts can get your email ID
                  blacklisted.
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    width: '100%'
                  }}
                >
                  <Checkbox
                    checked={isHighLimitOkay}
                    onChange={(e, checked) => {
                      setIsHighLimitOkay(checked)
                    }}
                  />
                  <Typography>I understand what I'm doing</Typography>
                </Box>
                <Box
                  sx={{
                    display: notChecked ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    p: 2,
                    backgroundColor: theme.palette.grey[200],
                    width: '100%'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: theme.palette.error.main
                    }}
                  >
                    <WarningRounded />
                  </Box>
                  <Typography>Check "I understand" to continue</Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    if (isHighLimitOkay) {
                      setNotChecked(false)
                      formik.handleSubmit()
                    } else {
                      setNotChecked(true)
                    }
                  }}
                  sx={{ color: theme.palette.error.main }}
                >
                  Continue
                </Button>
                <Button
                  onClick={() => {
                    setNotChecked(false)
                    setIsHighLimitOkay(false)
                    setHighdailyMaxLimitDialogOpen(false)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={boundHighdailyMaxLimitDialogOpen}
              onClose={highdailyMaxLimitBoundDialogClose}
              maxWidth='xs'
              fullWidth
            >
              <DialogTitle
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  color: '#28287B',
                  p: 4
                }}
              >
                Large daily limit
              </DialogTitle>
              <DialogContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  gap: 2,
                  p: 4
                }}
              >
                <Typography sx={{ color: theme.palette.error.main }}>
                  We regret to inform you that you are unable to select more than 5000 leads at a
                  time. This limitation is in place to ensure optimal performance and to provide a
                  seamless experience for all users.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setBoundHighdailyMaxLimitDialogOpen(false)
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Box
              sx={{
                boxShadow: '0px 0px 12px -1px rgba(0, 0, 0, 0.25)',
                borderRadius: 1,
                p: 3,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                mt: 2
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgb(33, 111, 237)",
                    borderRadius: "20px",
                    p: 1,
                  }}
                >
                  <PsychologyOutlined sx={{ color: "white" }} />
                </Box> */}

                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                  Delivery Optimization
                </Typography>
              </Box>
              <FormGroup sx={{ width: '100%', justifyContent: 'flex-start', mt: 2 }}>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label='Send emails as text-only (no HTML)'
                  sx={{ '& span': { fontSize: '13px', fontWeight: 400 } }}
                />
              </FormGroup>
            </Box>{' '}
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'none',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          mt: 3
        }}
      >
        <Button
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px' }}
          variant='outlined'
        >
          <SaveAltOutlined sx={{ mr: 1 }} />
          Save
        </Button>
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '16px',
            ml: 2
          }}
          variant='contained'
        >
          <RocketLaunch sx={{ mr: 1 }} />
          Launch
        </Button>
      </Box>
    </>
  )
}

export default CampaignOptions
