import React from 'react';
import { Menu, MenuItem, ListItemIcon, IconButton, alpha } from '@mui/material';
import { MarkAsUnread, BorderColor, PersonRemove, MoveToInbox, DeleteOutline,HubSharp } from '@mui/icons-material';

const InboxMoreMenu = ({
  anchorEl,
  open,
  handleClose,
  handleClickOpenLeadsDetailsDialog,
  handleClickOpenSendToHubspotDialog,
  handleClickOpenRemoveLeadDialog,
  handleClickOpenDeleteThreadDialog,
  handleClickOpenMoveToCampaignDialog,
  handlePortalEmailOpened,
  handleReload,
  replyId,
  theme,
  sent
}) => {
  const menuStyle = (label) => {
    return {
      py: 1.5,
      mx: 1,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#101828",
      fontWeight: 500,
      "&.MuiMenuItem-root .MuiListItemIcon-root .MuiSvgIcon-root": {
        color: "#101828",
      },
      "&:hover": {
        color: label === "delete" ? theme.palette.error.main : theme.palette.primary.main,
        backgroundColor:
          label === "delete"
            ? alpha(theme.palette.error.main, 0.1)
            : alpha(theme.palette.primary.main, 0.1),
        "&.MuiMenuItem-root .MuiListItemIcon-root .MuiSvgIcon-root": {
          color: label === "delete" ? theme.palette.error.main : theme.palette.primary.main,
        },
      },
      borderRadius: 1,
    };
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {!sent && (
        <MenuItem
          onClick={() => {
            handleClose();
            handlePortalEmailOpened(replyId._id, false);
          }}
          sx={menuStyle()}
        >
          <ListItemIcon>
            <MarkAsUnread fontSize="small" />
          </ListItemIcon>
          Mark as unread
        </MenuItem>
      )}
      <MenuItem onClick={handleClickOpenLeadsDetailsDialog} sx={menuStyle()}>
        <ListItemIcon>
          <BorderColor fontSize="small" />
        </ListItemIcon>
        Edit Lead
      </MenuItem>

      <MenuItem onClick={handleClickOpenRemoveLeadDialog} sx={menuStyle()}>
        <ListItemIcon>
          <PersonRemove fontSize="small" />
        </ListItemIcon>
        Remove Lead
      </MenuItem>
      <MenuItem onClick={handleClickOpenMoveToCampaignDialog} sx={menuStyle()}>
        <ListItemIcon>
          <MoveToInbox fontSize="small" />
        </ListItemIcon>
        Move Lead
      </MenuItem>
      <MenuItem onClick={handleClickOpenDeleteThreadDialog} sx={menuStyle("delete")}>
        <ListItemIcon>
          <DeleteOutline fontSize="small" />
        </ListItemIcon>
        Delete
      </MenuItem>
      <MenuItem onClick={handleClickOpenSendToHubspotDialog} sx={menuStyle()}>
        <ListItemIcon>
          <HubSharp fontSize="small" />
        </ListItemIcon>
        Sent To Hubspot
      </MenuItem>
    </Menu>
  );
};

export default InboxMoreMenu;
