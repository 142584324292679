import { LoginSocialFacebook } from 'reactjs-social-login'
import RedirectButton from 'src/components/RedirectButton'
import MetaLogo from 'src/assets/auth/meta.png';
import { config } from 'src/config';
import toast from 'react-hot-toast';

export const FacebookLoginButton = ({handleSocialSignUp}) => {
    const responseFacebook = (response) => {
        const { email, first_name, last_name, id } = response.data;
        const userData = {
            email,
            name: {
                first: first_name,
                last: last_name,
            },
            password: id,
            googleId: id,
        };
        handleSocialSignUp(userData, 'facebook');
    }
    return (
        <LoginSocialFacebook
            appId={config.FACEBOOK_OAUTH_APP_ID}
            onResolve={responseFacebook}
            onReject={(err) => toast.error('Something went wrong')}
        >
            <RedirectButton
                buttonImage={
                    <img
                        src={MetaLogo}
                        alt='facebook logo'
                        style={{ height: "16px", width: "16px" }}
                    />
                }
                buttonText='Continue with Meta'
                handleClick={() => { }}
                buttonStyle={{ marginTop: "18px" }}
            />
        </LoginSocialFacebook>
    )
}