import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SimpleLineChart = ({ row }) => {
  const intentData = row?.intent || [];
  // const intentData =  [
  //   {
  //     "date": "2024-07-07T00:00:00.000Z",
  //     "composite_score": 85,
  //     "topic": "Wound Care Products",
  //     "category": "Medical Treatment"
  //       },
  //   {
  //     "date": "2024-05-02T00:00:00.000Z",
  //     "composite_score": 49,
  //     "topic": "Channel Partner Program",
  //     "category": "CRM"
  //       },
  //   {
  //     "date": "2024-04-25T00:00:00.000Z",
  //     "composite_score": 12,
  //     "topic": "Channel Partner Program",
  //     "category": "CRM"
  //   },
  //   {
  //     "date": "2024-01-27T00:00:00.000Z",
  //     "composite_score": 1,
  //     "topic": "Channel Partner Program",
  //     "category": "CRM"
  //   }
  // ]
  // Extract data for the chart
  const labels = intentData.map((item) => new Date(item.date).toLocaleDateString()); // Convert dates to readable format
  const dataValues = intentData.map((item) => item.composite_score); // Extract composite scores

  const data = {
    labels, // Dates as labels
    datasets: [
      {
        label: 'Composite Score',
        data: dataValues, // Values for the line
        borderColor: 'rgb(75, 192, 192)', // Line color
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // Fill under the line
        tension: 0.4, // Smooth curve
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Intent Composite Score Over Time',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Composite Score',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default SimpleLineChart;


