import { Box, Dialog, DialogContent, DialogTitle, List, ListItem, Typography } from '@mui/material'

const PrivacyPolicyModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <DialogTitle
        sx={{
          fontSize: '2.5rem',
          fontWeight: '700',
          textAlign: 'center',
          color: '#282876'
        }}
      >
        Privacy Policy
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            '&>.MuiTypography-h4': {
              marginTop: '10px'
            },
            '&>.MuiTypography-h5': {
              marginTop: '6px'
            },
            '&>.MuiTypography-body2': {
              marginTop: '4px'
            },
            '&>.MuiList-root': {
              listStyle: 'disc inside'
            },
            '& .MuiListItem-root': {
              display: 'list-item',
              paddingBlock: 0,
              fontSize: '0.875rem'
            }
          }}
        >
          <Typography variant='caption'>Last Updated: October 17, 2024</Typography>
          <Typography variant='h5'>Introduction</Typography>

          <Typography variant='body2'>Welcome to Success.ai ("Success.ai," "we," "us," or "our"). We are committed to protecting your privacy and ensuring transparency in how we collect, use, store, and share your personal information. </Typography>

          <Typography variant='body2'>This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal data when you use our services, visit our website (www.success.ai), or interact with us in any way. </Typography>

          <Typography variant='body2'>By accessing or using our services, you agree to the terms of this Privacy Policy.</Typography>

          <Typography variant='body2'>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at support@success.ai.</Typography>

          <Typography variant='h4'>‍1. Consent</Typography>
          <Typography variant='body2'>By using our services or providing personal information through our website or other platforms owned by Success.ai, you consent to the collection, processing, storage, and use of your personal data as described in this Privacy Policy. You have the right to withdraw your consent at any time, subject to legal or contractual restrictions and reasonable notice. You also have the right to request access to, rectification of, or erasure of your personal data, as well as the right to data portability.</Typography>

          <Typography variant='h4'>2. Personal Data We Collect</Typography>
          <Typography variant='body2'>We collect personal data to provide and improve our services, comply with legal obligations, and protect our legitimate interests. The types of personal data we collect include:</Typography>

          <Typography variant='h5'>2.1 Identity and Contact Details</Typography>
          <Typography variant='body2'>We collect personal data to provide and improve our services, comply with legal obligations, and protect our legitimate interests. The types of personal data we collect include:</Typography>

          <List dense>
            <ListItem>Data Controller: Success.ai, a company registered under U.S. laws.</ListItem>
            <ListItem>Contact Email: support@success.ai</ListItem>
          </List>

          <Typography variant='h5'>2.2 Data Collected on the Site</Typography>
          <Typography variant='body2'>When you subscribe to our services, create an account, or interact with our site, we collect and manage the following personal data:</Typography>

          <List dense>
            <ListItem>Personal Information: First name, last name, email address, login credentials (username and password), mailing address, country, phone number, and IP address.</ListItem>
            <ListItem>Professional Information: Job title, company name, industry, and Intra-community VAT number (if applicable).</ListItem>
            <ListItem>Financial Information: Payment details necessary for processing transactions (processed securely via Stripe).</ListItem>
            <ListItem>Technical Information: Domain names, browser type, operating system, device information, and browsing data collected through cookies and similar technologies.</ListItem>
            <ListItem>Interaction Data: Order history, customer service requests, complaints, incidents, and messages exchanged with us.</ListItem>
            <ListItem>Marketing Preferences: Your preferences in receiving marketing communications from us.</ListItem>
          </List>

          <Typography variant='caption'>Note: We do not intentionally collect sensitive personal data such as government identifiers, complete credit card numbers, medical records, or other sensitive information. Please refrain from providing such data.</Typography>

          <Typography variant='h5'>2.3 Information Collected Automatically</Typography>
          <Typography variant='body2'>When you visit our website or use our services, we automatically collect certain information about your device and usage patterns:</Typography>

          <List>
            <ListItem>Usage Data: Information about how you use our website and services, including pages viewed, links clicked, and time spent on pages.</ListItem>
            <ListItem>Log Data: IP address, browser type, Internet service provider (ISP), referring/exit pages, date/time stamps, and clickstream data.</ListItem>
            <ListItem>Cookies and Similar Technologies: We use cookies, web beacons, and similar technologies to collect information about your interactions with our website. For more details, see our Cookie Policy.</ListItem>
          </List>
          <Typography variant='h5'>2.4 Information from Third Parties</Typography>
          <Typography variant='body2'>We may receive personal data about you from third parties, including:</Typography>

          <List>
            <ListItem>Data Providers: Publicly available sources, professional networking sites, and data aggregators.</ListItem>
            <ListItem>Partners and Affiliates: Companies with whom we have partnerships or data-sharing agreements.</ListItem>
            <ListItem>Service Providers: Entities that provide services on our behalf, such as payment processors, analytics providers, and marketing partners.</ListItem>
          </List>
          <Typography variant='caption'>Third-Party Payment Processor: We use Stripe for payment processing. Stripe's Privacy Policy is available at https://stripe.com/privacy.</Typography>

          <Typography variant='h4'>3. Purposes of Processing and Legal Basis</Typography>
          <Typography variant='body2'>We process your personal data for the following purposes, each associated with a legal basis under applicable data protection laws (e.g., GDPR):</Typography>

          <Typography variant='h5'>3.1 Provision of Services (Contractual Performance)</Typography>
          <List>
            <ListItem>Purpose: To create and manage your account, provide access to our services, process transactions, and facilitate verifications related to your account.</ListItem>
            <ListItem>Legal Basis: Processing is necessary for the performance of a contract to which you are a party.</ListItem>
          </List>
          <Typography variant='h5'>3.2 Customer Support and Communication (Legitimate Interests)</Typography>
          <List>
            <ListItem>Purpose: To respond to your inquiries, provide customer support, send administrative information, and communicate with you about our services.</ListItem>
            <ListItem>Legal Basis: Processing is necessary for our legitimate interests in providing efficient customer service and improving user experience.</ListItem>
          </List>
          <Typography variant='h5'>3.3 Marketing and Promotional Communications (Consent/Legitimate Interests)</Typography>
          <List>
            <ListItem>Purpose: To send newsletters, promotional materials, and information about our services and partner offers.</ListItem>
            <ListItem>Legal Basis: Processing is based on your consent (where required) or our legitimate interests in promoting our services.</ListItem>
          </List>
          <Typography variant='h5'>3.4 Personalization and Analytics (Legitimate Interests)</Typography>
          <List>
            <ListItem>Purpose: To personalize content, conduct analytics, understand user behavior, and improve our services.</ListItem>
            <ListItem>Legal Basis: Processing is necessary for our legitimate interests in enhancing our services and user experience.</ListItem>
          </List>
          <Typography variant='h5'>3.5 Legal Compliance and Security (Legal Obligations)</Typography>
          <List>
            <ListItem>Purpose: To comply with legal obligations, prevent fraud, detect and investigate prohibited or unlawful activities, and enforce our terms of use.</ListItem>
            <ListItem>Legal Basis: Processing is necessary for compliance with legal obligations and protection of vital interests.</ListItem>
          </List>
          <Typography variant='h5'>3.6 Sharing B2B Contact Data Publicly (Legitimate Interests/Public Interest)</Typography>
          <List>
            <ListItem>Purpose: To collect, process, and share business contact information (B2B data) publicly to facilitate professional networking and business opportunities.</ListItem>
            <ListItem>Legal Basis: Processing is necessary for our legitimate interests in providing B2B services and, where applicable, is conducted in the public interest.</ListItem>
          </List>

          <Typography variant='caption'>Note: We ensure that the sharing of B2B contact data complies with applicable laws and regulations, and we respect data subjects' rights to opt-out or object to such processing.</Typography>

          <Typography variant='h4'>4. Newsletter and Marketing Communications</Typography>
          <Typography variant='body2'>If you have subscribed to our newsletters or agreed to receive marketing communications, we may use your personal data to send you updates, promotions, and information about our services.</Typography>

          <List>
            <ListItem>Opt-Out: You can unsubscribe from our marketing emails at any time by clicking the "unsubscribe" link included in every email or by contacting us at support@success.ai.</ListItem>
            <ListItem>Preference Management: You may also update your communication preferences in your account settings.</ListItem>
          </List>

          <Typography variant='h4'>5. Email Statistics</Typography>
          <Typography variant='body2'>We may analyze email statistics to assess the performance of our email campaigns and improve our marketing strategies.</Typography>

          <List>
            <ListItem>Data Collected: Click rates, open rates, bounce rates, and other engagement metrics.</ListItem>
            <ListItem>Purpose: To understand how recipients interact with our emails and to enhance content relevance.</ListItem>
            <ListItem>Legal Basis: Processing is based on our legitimate interests in optimizing our marketing efforts.</ListItem>
          </List>

          <Typography variant='h4'>6. Testimonials</Typography>
          <Typography variant='body2'>We may publish customer testimonials on our website, which may include names, job titles, and company affiliations.</Typography>

          <List>
            <ListItem>Consent: We obtain your explicit consent before publishing any testimonial that includes your personal data.</ListItem>
            <ListItem>Withdrawal of Consent: If you wish to have your testimonial removed, please contact us at support@success.ai, and we will promptly address your request.</ListItem>
          </List>

          <Typography variant='h4'>7. How We Share Your Personal Data</Typography>
          <Typography variant='body2'>We may share your personal data with third parties under the following circumstances:</Typography>

          <Typography variant='h5'>7.1 Service Providers and Partners</Typography>

          <List>
            <ListItem>Purpose: To facilitate the provision of our services, we may share personal data with trusted third-party service providers who assist us in operating our website, conducting our business, and providing services to you.</ListItem>
            <ListItem>Examples: Payment processors (e.g., Stripe), cloud hosting services, analytics providers, marketing partners, and customer support platforms.</ListItem>
            <ListItem>Data Processing Agreements: We require all service providers to maintain the confidentiality and security of your personal data and to process it only for the purposes we specify.</ListItem>
          </List>

          <Typography variant='h5'>7.2 Legal Obligations and Protection</Typography>
          <List>
            <ListItem>Compliance with Laws: We may disclose your personal data to comply with legal obligations, court orders, or governmental requests.</ListItem>
            <ListItem>Protection of Rights: We may disclose data to enforce our terms of use, protect our rights, privacy, safety, or property, and that of our users and the public.</ListItem>
          </List>

          <Typography variant='h5'>7.3 Business Transfers</Typography>
          <List>
            <ListItem>Corporate Transactions: In the event of a merger, acquisition, restructuring, sale of assets, or bankruptcy, your personal data may be transferred to a successor or affiliated entity.</ListItem>
            <ListItem>Notification: We will notify you of any change in ownership or use of your personal data, as well as any choices you may have regarding your personal data.</ListItem>
          </List>

          <Typography variant='h5'>7.4 Aggregated and Anonymized Data</Typography>
          <List>
            <ListItem>Non-Identifiable Information: We may share aggregated or anonymized data that cannot reasonably be used to identify you, for analytics, marketing, or other business purposes.</ListItem>
          </List>

          <Typography variant='h5'>7.5 Sharing B2B Contact Data Publicly</Typography>
          <List>
            <ListItem>Purpose: To facilitate professional networking and business opportunities, we may share publicly available B2B contact data.</ListItem>
            <ListItem>Data Shared: Professional contact information such as name, job title, company name, business email address, and phone number.</ListItem>
            <ListItem>Legal Compliance: We ensure that sharing of B2B data complies with applicable laws, and we provide mechanisms for individuals to opt-out or request removal of their data.</ListItem>
          </List>

          <Typography variant='caption'>Note: We do not sell or rent your personal data to third parties for their marketing purposes without your explicit consent.</Typography>

          <Typography variant='h4'>8. Your Data Protection Rights</Typography>
          <Typography variant='body2'>Under applicable data protection laws (e.g., GDPR, CCPA), you have certain rights regarding your personal data:</Typography>

          <Typography variant='h5'>8.1 Right to Access</Typography>
          <List>
            <ListItem>Description: You have the right to request confirmation as to whether we process your personal data and to access the personal data we hold about you.</ListItem>
            <ListItem>How to Exercise: Submit a request to support@success.ai.</ListItem>
          </List>

          <Typography variant='h5'>8.2 Right to Rectification</Typography>
          <List>
            <ListItem>Description: You have the right to request correction of inaccurate or incomplete personal data.</ListItem>
            <ListItem>How to Exercise: Update your account information or contact us at support@success.ai.</ListItem>
          </List>

          <Typography variant='h5'>8.3 Right to Erasure (Right to be Forgotten)</Typography>
          <List>
            <ListItem>Description: You have the right to request the deletion of your personal data under certain circumstances.</ListItem>
            <ListItem>How to Exercise: Submit a request to support@success.ai.</ListItem>
          </List>

          <Typography variant='h5'>8.4 Right to Restrict Processing</Typography>
          <List>
            <ListItem>Description: You can request that we limit the processing of your personal data under specific conditions.</ListItem>
            <ListItem>How to Exercise: Contact us at support@success.ai.</ListItem>
          </List>

          <Typography variant='h5'>8.5 Right to Data Portability</Typography>
          <List>
            <ListItem>Description: You have the right to receive your personal data in a structured, commonly used, and machine-readable format and to transmit it to another controller.</ListItem>
            <ListItem>How to Exercise: Submit a request to support@success.ai.</ListItem>
          </List>

          <Typography variant='h5'>8.6 Right to Object</Typography>
          <List>
            <ListItem>Description: You have the right to object to the processing of your personal data for direct marketing purposes or when processing is based on legitimate interests.</ListItem>
            <ListItem>How to Exercise: Adjust your preferences in your account settings or contact us.</ListItem>
          </List>

          <Typography variant='h5'>8.7 Right to Withdraw Consent</Typography>
          <List>
            <ListItem>Description: If processing is based on your consent, you have the right to withdraw it at any time.</ListItem>
            <ListItem>How to Exercise: Update your preferences or contact us.</ListItem>
          </List>

          <Typography variant='h5'>8.8 Right to Lodge a Complaint</Typography>
          <List>
            <ListItem>Description: You have the right to lodge a complaint with a supervisory authority if you believe that our processing of your personal data violates applicable laws.</ListItem>
            <ListItem>Contact Information: [Insert relevant supervisory authority contact details]</ListItem>
            <ListItem>Verification: We may request proof of identity before fulfilling your request to ensure the security of your personal data.</ListItem>
            <ListItem>Response Time: We aim to respond to all legitimate requests within one month.</ListItem>
          </List>

          <Typography variant='h4'>9. Google API Compliance</Typography>
          <Typography variant='body2'>Our use of information received from Google APIs adheres to the Google API Services User Data Policy, including the Limited Use requirements.</Typography>

          <List>
            <ListItem>Data Access: When you connect your Google Account to our services, we access certain data, such as email content and contact lists, to provide our services.</ListItem>
            <ListItem>Data Use: We use this data solely to provide or improve our services and do not transfer it to third parties except as necessary to provide or improve the services, comply with applicable laws, or as part of a merger or acquisition.</ListItem>
            <ListItem>User Control: You can revoke our access to your Google Account at any time via your Google Account settings.</ListItem>
          </List>

          <Typography variant='h4'>10. Third-Party Data</Typography>
          <Typography variant='body2'>When using our services, we may have access to information contained in your email contact lists and email content.</Typography>

          <Typography variant='h5'>10.1 Data Access and Use</Typography>
          <List>
            <ListItem>Contact Lists: We access your contact lists to facilitate email communications and other services.</ListItem>
            <ListItem>Email Content: We may process email content to provide services such as email tracking, analytics, and automation.</ListItem>
          </List>
          <Typography variant='h5'>10.2 Data Security</Typography>
          <List>
            <ListItem>Access Control: Only authorized personnel have access to your contact lists and email content, and solely for purposes of providing support and improving our services.</ListItem>
            <ListItem>Data Storage: Your data is stored securely using industry-standard encryption and security protocols.</ListItem>
          </List>
          <Typography variant='h5'>10.3 User Control</Typography>
          <List>
            <ListItem>Data Export: You can export your contact lists and email data at any time through your account settings.</ListItem>
            <ListItem>Modification and Deletion: You can modify or delete contacts from your account as needed.</ListItem>
          </List>

          <Typography variant='h4'>11. Data Retention Periods</Typography>
          <Typography variant='body2'>We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including:</Typography>

          <Typography variant='h5'>11.1 Data for Service Execution</Typography>
          <List>
            <ListItem>Retention Period: As needed to provide services, and up to 3 years after account closure, unless a longer period is required by law.</ListItem>
            <ListItem>Purpose: To comply with legal obligations, resolve disputes, and enforce agreements.</ListItem>
          </List>
          <Typography variant='h5'>11.2 Marketing Data</Typography>
          <List>
            <ListItem>Retention Period: 3 years from the date of collection or your last active contact.</ListItem>
            <ListItem>Purpose: To send you marketing communications, unless you opt-out.</ListItem>
          </List>
          <Typography variant='h5'>11.3 Legal and Regulatory Compliance</Typography>
          <List>
            <ListItem>Evidentiary Data: Retained for the statutory limitation period (generally 5 years) for legal defense and compliance purposes.</ListItem>
          </List>
          <Typography variant='h5'>11.4 Data Subject Rights Requests</Typography>
          <List>
            <ListItem>Retention Period: Data used to verify identity is kept only for as long as necessary to process the request, then securely deleted.</ListItem>
          </List>
          <Typography variant='h5'>11.5 Anonymized Data</Typography>
          <List>
            <ListItem>Purpose: We may retain anonymized or aggregated data indefinitely for statistical analysis and business purposes.</ListItem>
          </List>

          <Typography variant='h4'>12. Data Storage and International Transfers</Typography>
          <Typography variant='h5'>12.1 Location of Data Storage</Typography>
          <List>
            <ListItem>Data Hosting: Our servers are located within the European Union (EU) and the United States.</ListItem>
            <ListItem>Cloud Providers: We use reputable cloud service providers that comply with applicable data protection regulations.</ListItem>
          </List>
          <Typography variant='h5'>12.2 International Data Transfers</Typography>
          <List>
            <ListItem>Cross-Border Transfers: Personal data may be transferred to countries outside your country of residence, including countries that may not provide the same level of data protection.</ListItem>
            <ListItem>Safeguards: We implement appropriate safeguards for international transfers, such as Standard Contractual Clauses approved by the European Commission or reliance on adequacy decisions.</ListItem>
          </List>
          <Typography variant='h5'>12.3 Your Rights</Typography>
          <List>
            <ListItem>Information: You have the right to be informed about the transfer of your personal data to third countries and the safeguards in place.</ListItem>
            <ListItem>Objection: You may object to international transfers by contacting us.</ListItem>
          </List>
          <Typography variant='h4'>13. Security Measures for Data Protection</Typography>
          <Typography variant='body2'>We prioritize the security of your personal data and implement industry-standard security measures to protect it from unauthorized access, disclosure, alteration, or destruction.</Typography>

          <Typography variant='h5'>13.1 Technical and Organizational Measures</Typography>
          <List>
            <ListItem>Encryption: Data is encrypted in transit and at rest using secure protocols (e.g., SSL/TLS).</ListItem>
            <ListItem>Access Control: Strict access controls to personal data, limited to authorized personnel with a need-to-know basis.</ListItem>
            <ListItem>Firewalls and Intrusion Detection: Use of firewalls, intrusion detection systems, and regular security assessments.</ListItem>
          </List>
          <Typography variant='h5'>13.2 Incident Response</Typography>
          <List>
            <ListItem>Security Breach Notification: In the event of a data breach, we will notify affected individuals and regulatory authorities as required by law.</ListItem>
            <ListItem>Remediation: We will take prompt action to mitigate any potential harm and prevent future occurrences.</ListItem>
          </List>
          <Typography variant='h5'>13.3 User Responsibilities</Typography>
          <List>
            <ListItem>Account Security: You are responsible for maintaining the confidentiality of your account credentials and for restricting access to your account.</ListItem>
            <ListItem>Awareness: Be vigilant against phishing and other unauthorized attempts to access your personal data.</ListItem>
            <ListItem>Disclaimer: While we strive to protect your personal data, no method of transmission over the Internet or electronic storage is completely secure. We cannot guarantee absolute security.</ListItem>
          </List>

          <Typography variant='h4'>14. Cookie Policy</Typography>
          <Typography variant='body2'>Our website uses cookies and similar technologies to enhance your experience and analyze usage.</Typography>

          <Typography variant='h5'>14.1 What Are Cookies?</Typography>
          <List>
            <ListItem>Definition: Cookies are small text files stored on your device when you visit a website.</ListItem>
            <ListItem>Types of Cookies: some text</ListItem>
            <ListItem>Essential Cookies: Necessary for the website to function properly.</ListItem>
            <ListItem>Analytical/Performance Cookies: Collect information about how visitors use the website.</ListItem>
            <ListItem>Functionality Cookies: Remember your preferences and personalize content.</ListItem>
            <ListItem>Targeting/Advertising Cookies: Deliver relevant advertisements.</ListItem>
          </List>
          <Typography variant='h5'>14.2 How We Use Cookies</Typography>
          <List>
            <ListItem>Purpose: To remember user preferences, analyze site traffic, and deliver personalized content and advertising.</ListItem>
            <ListItem>Third-Party Cookies: We may allow third parties to place cookies on our site for analytics and advertising purposes.</ListItem>
          </List>
          <Typography variant='h5'>14.3 Your Choices</Typography>
          <List>
            <ListItem>Cookie Consent: Upon your first visit, you will be prompted to accept or decline cookies.</ListItem>
            <ListItem>Cookie Settings: You can manage your cookie preferences at any time through your browser settings.</ListItem>
          </List>

          <Typography variant='body2'>Opt-Out: You may opt-out of certain cookies, but this may affect your ability to use some features of our website.</Typography>
          <Typography variant='h4'>15. Privacy Policy Changes</Typography>
          <Typography variant='body2'>We reserve the right to update or modify this Privacy Policy at any time to reflect changes in our practices, legal requirements, or for other operational reasons.</Typography>

          <Typography variant='h5'>15.1 Notification of Changes</Typography>
          <List>
            <ListItem>Advance Notice: We will notify you of significant changes via our website or email at least 30 days before they take effect.</ListItem>
            <ListItem>Effective Date: The "Effective Date" at the top of this Privacy Policy indicates when the policy was last revised.</ListItem>
          </List>
          <Typography variant='h5'>15.2 Your Continued Use</Typography>
          <List>
            <ListItem>Acceptance of Changes: Your continued use of our services after the effective date constitutes your acceptance of the revised Privacy Policy.</ListItem>
          </List>
          <Typography variant='h5'>15.3 Review Regularly</Typography>
          <List>
            <ListItem>Stay Informed: We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your personal data.</ListItem>
          </List>

          <Typography variant='h4'>16. Children's Privacy</Typography>
          <Typography variant='body2'>Our services are not intended for individuals under the age of 18.</Typography>

          <List>
            <ListItem>No Intentional Collection: We do not knowingly collect personal data from children under 18.</ListItem>
            <ListItem>Parental Involvement: If you are a parent or guardian and believe your child has provided us with personal data, please contact us.</ListItem>
            <ListItem>Data Deletion: If we become aware that we have inadvertently collected personal data from a child under 18, we will take steps to delete such information promptly.</ListItem>
          </List>

          <Typography variant='h4'>17. Contact Us</Typography>
          <Typography variant='body2'>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us:</Typography>

          <List>
            <ListItem>Email: support@success.ai</ListItem>
          </List>

          <Typography variant='body2'>We are committed to addressing your inquiries and resolving any concerns about your privacy.</Typography>

          <Typography variant='body2'>Thank you for trusting Success.ai with your personal information. We are committed to protecting your privacy and ensuring transparency in all our data handling practices.</Typography>

          <Typography variant='caption'>Important Notice</Typography>
          <br />
          <Typography variant='caption'>This Privacy Policy is intended to provide a comprehensive overview of our data practices. However, it may not cover all legal obligations specific to your business or jurisdiction. We strongly recommend consulting with a qualified attorney to review and tailor this Privacy Policy to ensure full compliance with all applicable laws and regulations.</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default PrivacyPolicyModal
