import axios from 'axios'
import { getAuthToken } from 'src/services/auth-service'

// Set up the interceptor
axios.interceptors.request.use(
  (config) => {
    // Check if the request URL matches one of the allowed domains
    const allowedDomains = ['api.success.ai', 'apistaging.success.ai', 'localhost:3300']
    if (config.url && allowedDomains.some((domain) => config.url.includes(domain))) {
      const token = getAuthToken()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axios
