import { memo, useEffect, useRef } from 'react'
import { Stack, Typography, Box, Card, CardContent, Avatar } from '@mui/material'
import Section1Img1 from 'src/assets/auth/signup/section1-1.png'
import Section1Img2 from 'src/assets/auth/signup/section1-2.png'
import Section1Img3 from 'src/assets/auth/signup/section1-3.png'
import Section1Img4 from 'src/assets/auth/signup/section1-4.png'
import Section1Img5 from 'src/assets/auth/signup/section1-5.png'
import Section1Img6 from 'src/assets/auth/signup/section1-6.png'
import Section1Img7 from 'src/assets/auth/signup/section1-7.png'
import Section1Img8 from 'src/assets/auth/signup/section1-8.png'
import Section1Img9 from 'src/assets/auth/signup/section1-9.png'
import Section1Img10 from 'src/assets/auth/signup/section1-10.png'
import Section1Img11 from 'src/assets/auth/signup/section1-11.png'
import Section1Img12 from 'src/assets/auth/signup/section1-12.png'
import LogoFoodFirst from 'src/assets/auth/signup/logo-foodfirst.png'
import LogoGetApp from 'src/assets/auth/signup/logo-get-app.png'
import LogoOracle from 'src/assets/auth/signup/logo-oracle.png'
import LogoCart from 'src/assets/auth/signup/logo-carta.png'
import LogoPG from 'src/assets/auth/signup/logo-P&G.png'
import LogoSoftwareAdvice from 'src/assets/auth/signup/logo-software-advice.png'
import LogoBrandHeroes from 'src/assets/auth/signup/logo-brandheroes.png'

const RegisterAnimation = memo(() => {
  return (
    <Stack
      sx={{ width: '100%', position: 'relative' }}
      spacing={4}
      gap={6}
      justifyContent='space-between'
    >
      <Typography
        variant='h2'
        sx={{
          fontWeight: 'bold',
          fontSize: 30,
          maxWidth: 600,
          lineHeight: 1.5,
          color: 'white',
          padding: '5rem 5rem 0 5rem'
        }}
      >
        The World's First Unlimited B2B Leads & AI Outreach
      </Typography>

      <Box
        sx={{
          mask: 'linear-gradient(to right, transparent, white 15%, white 85%, transparent)',
          '&>*:not(:first-child)': {
            marginTop: '1rem'
          }
        }}
      >
        <Marquee direction='forwards'>
          {testimonials.slice(0, 4).map((t) => (
            <TestimonialCard key={t.name} name={t.name} paragraph={t.paragraph} img={t.img} />
          ))}
        </Marquee>
        <Marquee direction='reverse'>
          {testimonials.slice(4, 8).map((t) => (
            <TestimonialCard key={t.name} name={t.name} paragraph={t.paragraph} img={t.img} />
          ))}
        </Marquee>
        <Marquee direction='forwards'>
          {testimonials.slice(8).map((t) => (
            <TestimonialCard key={t.name} name={t.name} paragraph={t.paragraph} img={t.img} />
          ))}
        </Marquee>
      </Box>

      <Box sx={{ mask: 'linear-gradient(to right, transparent, white 15%, white 85%, transparent)' }}>
        <Marquee direction='reverse' spacing={4}>
          {[
            LogoFoodFirst,
            LogoGetApp,
            LogoOracle,
            LogoCart,
            LogoPG,
            LogoSoftwareAdvice,
            LogoBrandHeroes
          ].map((logo, i) => (
            <BrandLogo key={i} img={logo} />
          ))}
        </Marquee>
      </Box>
    </Stack>
  )
})

export default RegisterAnimation

const Marquee = ({ children, direction, spacing = 2, speed = '90s' }) => {
  const scrollContainerRef = useRef(null)

  useEffect(() => {
    if (!scrollContainerRef) return

    const scrollerInner = scrollContainerRef.current
    const scrollerContent = Array.from(scrollerInner.children)

    scrollerContent.forEach((item) => {
      const duplicatedItem = item.cloneNode(true)
      duplicatedItem.setAttribute('aria-hidden', true)
      scrollerInner.appendChild(duplicatedItem)
    })

    return () => {
      const duplicatedItems = Array.from(scrollerInner.querySelectorAll('[aria-hidden="true"]'))
      duplicatedItems.forEach((item) => {
        scrollerInner.removeChild(item)
      })
    }
  }, [])

  return (
    <Stack
      ref={scrollContainerRef}
      flexWrap='nowrap'
      direction='row'
      spacing={spacing}
      alignItems='center'
      justifyContent='space-between'
      sx={{
        '@keyframes scroll': {
          to: { transform: 'translate(calc(-50% - 0.5rem))' }
        },
        animation: `scroll ${speed} ${direction} linear infinite`,
        width: 'max-content'
      }}
    >
      {children}
    </Stack>
  )
}

const TestimonialCard = ({ name, paragraph, img }) => {
  return (
    <Card
      sx={{
        maxWidth: 600,
        minWidth: 600,
        p: 1,
        background: 'rgb(255 255 255 / 0.1)',
        color: '#fff',
        border: '1px solid rgb(255 255 255 / 0.15)',
        borderRadius: '10px',
        boxShadow: 'none'
      }}
    >
      <CardContent>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Avatar
            alt={name}
            src={img}
            sx={{
              width: 64,
              height: 64,
              borderTopRightRadius: 25,
              borderTopLeftRadius: 25,
              borderBottomLeftRadius: 25,
              borderBottomRightRadius: 10
            }}
          />
          <Stack direction='column' spacing={0.5}>
            <Typography component='p' sx={{ fontWeight: 'semibold' }}>
              {paragraph}
            </Typography>
            <Typography component='p' sx={{ fontSize: '14px' }}>
              {name}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

const BrandLogo = ({ img }) => {
  return (
    <Box>
      <Box
        component='img'
        src={img}
        sx={{
          height: '40px',
          width: 'auto',
          display: 'block',
          filter: 'brightness(0) invert(1)',
          objectFit: 'contain'
        }}
        alt='Section Image'
      />
    </Box>
  )
}

const testimonials = [
  {
    img: Section1Img1,
    name: 'Riley James',
    paragraph:
      'This platform has helped us maintain consistency across all our departments. It’s unifying.'
  },
  {
    img: Section1Img3,
    name: 'Ashlyn Lee at Success.ai',
    paragraph:
      'I love how this platform turns complex data into easy-to-understand visualizations.'
  },
  {
    img: Section1Img2,
    name: 'Grace Ramirez',
    paragraph:
      'This tool has improved our project visibility. We always know where things stand now.'
  },
  {
    img: Section1Img4,
    name: 'Cole Jenkins',
    paragraph:
      'We’ve been able to automate so many tedious tasks. It’s freed up time for more important work.'
  },
  {
    img: Section1Img5,
    name: 'Dawson Smith',
    paragraph:
      'We’ve seen a significant ROI since adopting this solution. It’s been a game-changer for us.'
  },
  {
    img: Section1Img6,
    name: 'Hannah Carter',
    paragraph:
      'The platform’s predictive analytics have helped us make proactive decisions. It’s like having a crystal ball.'
  },
  {
    img: Section1Img7,
    name: 'Henry Clark',
    paragraph:
      'We’ve been able to automate so many tedious tasks. It’s freed up time for more important work.'
  },
  {
    img: Section1Img8,
    name: 'Scarlett Sanchez',
    paragraph:
      'The UI is clean and modern. It’s actually enjoyable to use, which is rare for business software.'
  },
  {
    img: Section1Img9,
    name: 'Mackenzie Hughes',
    paragraph: 'The level of detail in the audit trails is fantastic. It makes compliance easy.'
  },
  {
    img: Section1Img10,
    name: 'Lydia Wood',
    paragraph:
      'The platform’s predictive analytics have helped us make proactive decisions. It’s like having a crystal ball.'
  },
  {
    img: Section1Img11,
    name: 'Ethan Taylor',
    paragraph:
      'We’ve been able to automate so many tedious tasks. It’s freed up time for more important work.'
  },
  {
    img: Section1Img12,
    name: 'Dawson Smith',
    paragraph:
      'The UI is clean and modern. It’s actually enjoyable to use, which is rare for business software.'
  }
]
