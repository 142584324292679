import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Upgrade } from 'src/icons/upgrade';

const UpgradeToPremiumButton = ({ navMenuOpen, isMobile }) => {
  const navigate = useNavigate();

  // Handler for navigating to the billing page
  const handleExplorePlans = () => {
    navigate('/settings/billing');
  };

  // Determine if the button or logo should be shown
  const showButton = navMenuOpen || isMobile;

  return (
    <Box>
      {showButton ? (
        <Button
          onClick={handleExplorePlans}
          sx={{
            width: '174px',
            height: '34px',
            padding: '5px 8px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
            textTransform: 'none', // Prevent text from being capitalized
            '&:hover': {
              backgroundColor: '#ffffff', // Keep the background color the same on hover
            },
          }}
        >
          <Upgrade />
          <Typography
            sx={{
              fontFamily: 'Inter, sans-serif',
              fontSize: '13px',
              fontWeight: 600,
              lineHeight: '14px',
              color: '#000',
              whiteSpace: 'nowrap',
            }}
          >
            Upgrade to Premium
          </Typography>
        </Button>
      ) : (
        <Box
          onClick={handleExplorePlans}
          sx={{
            height: '32.36px',
            width: '32.36px',
            minWidth: '0',
            cursor: 'pointer',
            padding: '4.18px 4.18px',
            borderRadius: '6.68px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8.36px',
            textTransform: 'none',
            background: 'linear-gradient(to top, #E7EEFF, #FFFFFF)',
          }}
        >
          <Upgrade />
        </Box>
      )}
    </Box>
  );
};


export default UpgradeToPremiumButton
