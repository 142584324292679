import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SimpleLineChart from 'src/pages/SimpleBarChart';
import { DialogTitle, DialogContent } from '@mui/material';

const SimpleBarChartDialog = ({ open, score, onClose, date, row }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      {/* Close button */}
      <IconButton
        aria-label="close"
        onClick={onClose}
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'grey',
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
    <SimpleLineChart value={score} date={date} row={row} />
    </DialogContent>
  </Dialog>
);

export default SimpleBarChartDialog;
