import React from 'react'
import { Typography, Button, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {Starwhite} from '../icons/starwhite'

const ApiUpgradeButton = () => {
  const navigate = useNavigate()
  // Handler for navigating to the billing page
  const handleExplorePlans = () => {
    navigate('/settings/billing') // Use navigate to go to internal route
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ height: '58px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          variant='contained' onClick={handleExplorePlans} 
          color='primary'
          sx={{
            height: '100%',
            width: '100%',
            boxShadow: 'none',
            gap: '5px',
            '&:hover': {
              backgroundColor: 'none  ', //  'none' to remove the effect
              boxShadow: 'none'
            }
          }}
        >
          <Starwhite sx={{ height: '18px', width: '22px' }} />
          <Typography
            align='center' sx={{
              fontFamily: 'Inter, sans-serif',
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '16.09px',
              textAlign: 'center',
              color: '#FFFFFF',
              textTransform: 'none'
            }}
          >
            Explore Plans
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default ApiUpgradeButton
