import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const FreeForeverConfirmation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const encodedEmail = searchParams.get('token');

  useEffect(() => {
      navigate(`/register/verify-email?token=${encodedEmail}`);

  }, [navigate, encodedEmail, location]);

  return (
    <></>
  );
};

export default FreeForeverConfirmation;
