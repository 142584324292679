import React from 'react'

const RedirectButton = ({ handleClick, buttonText, buttonImage, buttonStyle, textStyle }) => {
  return (
    <div
      onClick={handleClick}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #E4E4E5',
        borderRadius: '8px',
        cursor: 'pointer',
        padding: '12px 0px',
        gap: '10px',
        ...buttonStyle
      }}
    >
      {buttonImage}
      <div
        style={{
          marginRight: '14px',
          fontSize: '13px',
          color: '#101010',
          fontWeight: 600,
          ...textStyle
        }}
      >
        {buttonText}
      </div>
    </div>
  )
}

export default RedirectButton
