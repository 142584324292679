import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
  useTheme,
  alpha,
  useMediaQuery,
  InputAdornment,
  IconButton,
  Divider,
  OutlinedInput,
  Checkbox,
  ButtonBase
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Logo } from 'src/components/logo'
import {
  setAuthToken,
  useRegisterMutation,
  useGetUserMutation,
  useUpdateUserMutation,
  useLoginMutation
} from 'src/services/auth-service.js'
import * as Yup from 'yup'
import SignupImage from '../../assets/auth/signup.png'
import jwtDecode from 'jwt-decode'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import FacebookLogo from 'src/assets/auth/facebook.png'
import { LoginSocialFacebook } from 'reactjs-social-login'
import { useGoogleLogin } from '@react-oauth/google'
import SuccessAiBlueLogo from 'src/assets/successAiBlueLogo.svg'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from 'src/msalConfig'
import toast from 'react-hot-toast'
import { useLazyGetMeQuery, useLazyUpdateGoogleTrackingQuery } from 'src/services/user-service'
import { useLazyGetCurrentPlanQuery } from 'src/services/billing-service'
import RedirectButton from 'src/components/RedirectButton'
import { GoogleConnect } from 'src/assets/GoogleConnect'
import RegisterAnimation from './registerAnimation'
import axios from 'axios'
import MicrosoftLogo from 'src/assets/auth/microsoft-icon.png'
import GoogleLogo from 'src/assets/auth/google-icon.png'
import AppleLogo from 'src/assets/auth/apple-icon.png'
import { config } from 'src/config'
import { FacebookLoginButton } from './FacebookLoginButton'
import PrivacyPolicyModal from './privacyPolicyModal'
import TermsAndConditionsModal from './termsAndConditionsModal'

const RegisterPage = () => {
  const accounts = localStorage.getItem('auth_token')
  const navigate = useNavigate()
  const [register] = useRegisterMutation()
  const [login] = useLoginMutation()
  const [getUser] = useGetUserMutation()
  const [getme, { data }] = useLazyGetMeQuery()
  const [getCurrentPlan, { data: planData }] = useLazyGetCurrentPlanQuery()
  const [updateTracking, { datas }] = useLazyUpdateGoogleTrackingQuery()
  const [rememberMe, setRememberMe] = useState(false)
  const [updateUser] = useUpdateUserMutation()
  const [queryParameters] = useSearchParams()
  const sumo = queryParameters.get('appsumo')
  const encodedPartnerKey = queryParameters.get('ps_partner_key')
  const [user, setUser] = useState({})
  const { email } = useParams()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [openTermsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false)
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false)

  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const handleRedirect = () => {
    instance
      .loginPopup({
        ...loginRequest,
        prompt: 'select_account'
      })
      .catch((error) => {
        instance.clearCache()
        return toast.error(error.message)
      })
  }

  useEffect(() => {
    if (!activeAccount) {
      return
    }
    const decoded = jwtDecode(activeAccount.idToken)
    instance.setActiveAccount(null)
    sessionStorage.clear()

    const userData = {
      email: decoded.preferred_username,
      name: {
        first: decoded.name.split(' ')[0],
        last: decoded.name.split(' ')[1] || ''
      },
      password: decoded.sub,
      googleId: decoded.sub
    }

    handleSocialSignUp(userData, 'microsoft')
  }, [activeAccount])

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleMouseUpPassword = (event) => {
    event.preventDefault()
  }
  //
  let newEmail
  if (email) {
    newEmail = window?.atob(email)
  }

  const [searchParams] = useSearchParams()
  const invitedEmail = searchParams.get('email')
  const validationSchema = () => {
    let schema = Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required')
        .test('no-plus', 'Please add a valid email address', (value) => {
          return !value || !value.includes('+')
        }),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .max(255)
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
        .required('Password is required'),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
      terms: Yup.bool().oneOf([true], 'You must accept the Terms and Conditions'),
    })

    if (!newEmail) {
      schema = schema.shape({
        name: Yup.object({
          first: Yup.string(),
          // .required("First Name is required")
          // .matches(/^[aA-zZ]+$/, "Only alphabets are allowed"),
          last: Yup.string()
          // .required("Last Name is required")
          // .matches(/^[aA-zZ]+$/, "Only alphabets are allowed"),
        }),
        password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .max(255)
          .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
          .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
          .matches(/\d/, 'Password must contain at least one number')
          .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
          .required('Password is required')
      })
    }

    return schema
  }

  const formik = useFormik({
    initialValues: {
      name: {
        first: '',
        last: ''
      },
      email: newEmail || invitedEmail || '',
      password: '',
      confirmPassword: '',
      sumo: sumo || '',
      terms: false,
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const { terms, ...submissionData } = values;
        if (newEmail && accounts) {
          await handleSignUp(submissionData, helpers, true);
        } else {
          await handleSignUp(submissionData, helpers);
        }
      } catch (err) {
        helpers.setErrors({ submit: err.response?.data?.error?.message || err.message });
      }
    },
  })

  const theme = useTheme()
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  useEffect(() => {
    if (data && data?.isTrackedByGoogleAds) {
      toast.success('Successfully logged in')
      navigate('/accounts')
      return
    }
    if (data && planData) {
      if (
        data.isEarlyBirdAccessUser &&
        !data.isEarlyBirdDealRefund &&
        !data?.isTrackedByGoogleAds
      ) {
        updateTracking()
        toast.success('Successfully logged in')
        navigate('/accounts?user=lifetimepaidUser')
        return
      }

      if (
        data &&
        (planData.subscription?.sendingWarmup?.expiresAt || planData.subscription?.leads?.expiresAt)
      ) {
        updateTracking()
        toast.success('Successfully logged in')
        navigate('/accounts?user=generalpaiduser')
      } else {
        if (!data?.isTrackedByGoogleAds) {
          updateTracking()
          navigate('/accounts?user=freeUser')
          toast.success('Successfully logged in')
        } else {
          navigate('/accounts')
          toast.success('Successfully logged in')
        }
      }
    } else if (data && data?.appSumoCode) {
      updateTracking()
      toast.success('Successfully logged in')
      navigate('/accounts')
    }
  }, [data, planData])

  useEffect(() => {
    if (newEmail) {
      const timer = setTimeout(async () => {
        const user = await getUser(newEmail).unwrap()
        setUser(user)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [getUser])

  const handleSocialSignUp = async (values, provider) => {
    let stripeCustomerId
    try {
      const result = await register(values)
      if (
        result?.error &&
        result?.error.status === 400 &&
        result?.error.data.error.message === 'Email already exists'
      ) {
        const { email, password, ...rest } = values
        const reqObj = {
          email,
          password,
          [`is${provider.charAt(0).toUpperCase() + provider.slice(1)}Login`]: true
        }

        const response = await login(reqObj)

        if (response?.data?.authToken?.message === 'Different provider login') {
          const userResponse = confirm(
            'Account with this email already exist with some other provider. Do you want to continue with that account?'
          )
          if (userResponse) {
            setAuthToken(response?.data?.authToken?.authToken)
            await getme()
            await getCurrentPlan()
            return
          } else return
        }
        if (response.error) {
          toast.error(response.error.data.error.message || 'Something went wrong')
          return
        }

        setAuthToken(response?.data?.authToken?.authToken)
        await getme()
        await getCurrentPlan()
        return
      }
      if (result?.error) {
        toast.error(result.error.data.error.message || 'Something went wrong')
        return
      }
      ({ stripeCustomerId } = result?.data)
      setAuthToken(result?.data?.authToken)
      await getme()
      await getCurrentPlan()
      // /*****
      //  * Store data in growsumo and createSignup method call for create a customer
      //  *****/
      growsumo.data.name = `${values?.name?.first} ${values?.name?.last}`
      growsumo.data.email = values?.email
      growsumo.data.customer_key = values?.email
      growsumo.data.partner_key = atob(encodedPartnerKey)
      growsumo.data.provider_key = stripeCustomerId
      if (stripeCustomerId && encodedPartnerKey) {
        growsumo.createSignup()
      }
    } catch (err) {
      toast.error(err)
    }
  }

  const handleSignUp = async (values, helpers, isUpdate = false) => {
    let stripeCustomerId, updatedUser
    try {
      if (isUpdate) {
        ({ updatedUser, stripeCustomerId } = await updateUser({
          id: user._id,
          data: { email: values.email }
        }).unwrap())
      } else {
        const { confirmPassword, ...rest } = values
        values = rest
        const result = await register(values).unwrap();
        ({ stripeCustomerId } = result)
        setAuthToken(result.authToken)
      }
      /*****
       * Store data in growsumo and createSignup method call for create a customer
       *****/
      growsumo.data.name = isUpdate
        ? `${updatedUser?.name?.first} ${updatedUser?.name?.last}`
        : `${values?.name?.first} ${values?.name?.last}`
      growsumo.data.email = isUpdate ? updatedUser?.email : values?.email
      growsumo.data.customer_key = isUpdate ? updatedUser?.email : values?.email
      growsumo.data.partner_key = atob(encodedPartnerKey)
      growsumo.data.provider_key = stripeCustomerId
      if (stripeCustomerId && encodedPartnerKey) {
        growsumo.createSignup()
      }

      const encodedEmail = window.btoa(isUpdate ? updatedUser.email : values.email)

      navigate(`/signup-confirmation?token=${encodedEmail}`)
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message })
    }
  }

  const againLogin = async () => {
    localStorage.clear()
    navigate('/login')
  }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const accessToken = response.access_token
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })

        const { email, given_name, family_name, sub } = userInfoResponse.data
        const userData = {
          email,
          name: {
            first: given_name,
            last: family_name
          },
          password: sub,
          googleId: sub
        }

        handleSocialSignUp(userData, 'google')
      } catch (error) {
        toast.error(error)
      }
    },
    onError: (error) => {
      toast.error(error)
    }
  })

  return (
    <>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'flex-start',
          // alignItems: "center",
          backgroundColor: '#F2F4F6'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: isMdUp ? '50%' : '100%',
            paddingBlock: '24px',
            paddingInline: '5%',
            backgroundColor: theme.palette.background.default
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              alignItems: 'flex-start',
              justifyContent: 'center',
              maxWidth: '550px',
              width: '100%',
              marginInline: 'auto'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '16px',
                paddingTop: '16px'
              }}
            >
              <Box>
                <img src={SuccessAiBlueLogo} style={{ height: '36px', width: 'auto' }} />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  borderRadius: '12px',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Typography
                    sx={{
                      width: '100%',
                      fontSize: '26px',
                      fontWeight: 600,
                      color: '#101010'
                    }}
                  >
                    Create an account
                  </Typography>
                </Box>

                <RedirectButton
                  buttonImage={
                    <img
                      src={GoogleLogo}
                      alt='google logo'
                      style={{ height: '16px', width: '16px' }}
                    />
                  }
                  buttonText='Create with Google'
                  handleClick={handleGoogleLogin}
                  buttonStyle={{ marginTop: '18px' }}
                />
                <RedirectButton
                  buttonImage={
                    <img
                      src={MicrosoftLogo}
                      alt='microsoft logo'
                      style={{ height: '16px', width: '16px' }}
                    />
                  }
                  buttonText='Create with Microsoft'
                  buttonStyle={{ marginTop: '12px' }}
                  handleClick={handleRedirect}
                />
                <FacebookLoginButton
                  handleSocialSignUp={handleSocialSignUp}
                />
                {/* <RedirectButton
                  buttonImage={<img src={AppleLogo} alt="apple logo" style={{ height: '16px', width: '16px' }} />}
                  buttonText="Continue with Apple"
                  buttonStyle={{ marginTop: "12px" }}
                  handleClick={() => {}}
                /> */}

                <Box display='flex' alignItems='center' width='100%' mt={2}>
                  <Divider sx={{ flex: 1, borderColor: '#D7D9E6' }} />
                  <Typography sx={{ mx: 2, color: '#92979C', fontSize: '13px' }}>or</Typography>
                  <Divider sx={{ flex: 1, borderColor: '#D7D9E6' }} />
                </Box>

                <form noValidate onSubmit={formik.handleSubmit}>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={isMdUp ? 6 : 12}>
                      {' '}
                      <Typography
                        sx={{
                          width: '100%',
                          textAlign: 'left',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          color: '#101010',
                          mt: 2
                        }}
                      >
                        First name *
                      </Typography>
                      <TextField
                        variant='outlined'
                        error={!!(formik.touched.name?.first && formik.errors.name?.first)}
                        fullWidth
                        helperText={formik.touched.name?.first && formik.errors.name?.first}
                        name='name.first'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={
                          newEmail && accounts ? user?.name?.first : formik?.values?.name?.first
                        }
                        placeholder='Enter first name'
                        disabled={newEmail && accounts}
                        sx={{
                          mt: 1,
                          width:
                            isMdUp || user?.name?.first || formik?.values?.name?.first
                              ? '100%'
                              : '60%',
                          // height: 40,
                          backgroundColor: 'white',
                          '& div': { pl: 0.3 },
                          '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                          '& div input': {
                            py: 1.5,
                            fontSize: '13px',
                            fontWeight: 400,
                            letterSpacing: '0em',
                            '&::placeholder': {
                              color: 'rgba(40, 40, 123, 0.5)'
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={isMdUp ? 6 : 12}>
                      {' '}
                      <Typography
                        sx={{
                          width: '100%',
                          textAlign: 'left',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          color: '#101010',
                          mt: 2
                        }}
                      >
                        Last name *
                      </Typography>
                      <TextField
                        variant='outlined'
                        error={!!(formik.touched.name?.last && formik.errors.name?.last)}
                        fullWidth
                        helperText={formik.touched.name?.last && formik.errors.name?.last}
                        name='name.last'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={newEmail && accounts ? user?.name?.last : formik?.values?.name?.last}
                        disabled={newEmail && accounts}
                        placeholder='Enter last name'
                        sx={{
                          mt: 1,
                          width:
                            isMdUp || user?.name?.last || formik?.values?.name?.last
                              ? '100%'
                              : '60%',
                          // height: 40,
                          backgroundColor: 'white',
                          '& div': { pl: 0.3 },
                          '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                          '& div input': {
                            py: 1.5,
                            fontSize: '13px',
                            fontWeight: 400,
                            letterSpacing: '0em',
                            '&::placeholder': {
                              color: 'rgba(40, 40, 123, 0.5)'
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      {' '}
                      <Typography
                        sx={{
                          width: '100%',
                          textAlign: 'left',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          color: '#101010',
                          mt: 2
                        }}
                      >
                        Email *
                      </Typography>
                      <TextField
                        variant='outlined'
                        error={!!(formik.touched.email && formik.errors.email)}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        name='email'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type='email'
                        value={formik.values.email}
                        placeholder='Enter email'
                        disabled={!!invitedEmail}
                        sx={{
                          mt: 1,
                          width: '100%',
                          // height: 40,
                          backgroundColor: 'white',
                          '& div': { pl: 0.3 },
                          '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                          '& div input': {
                            py: 1.5,
                            fontSize: '13px',
                            fontWeight: 400,
                            letterSpacing: '0em',
                            '&::placeholder': {
                              color: 'rgba(40, 40, 123, 0.5)'
                            }
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {' '}
                      <Typography
                        sx={{
                          width: '100%',
                          textAlign: 'left',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          color: '#101010',
                          mt: 2
                        }}
                      >
                        Password *
                      </Typography>
                      <TextField
                        variant='outlined'
                        error={!!(formik.touched.password && formik.errors.password)}
                        fullWidth
                        helperText={formik.touched.password && formik.errors.password}
                        name='password'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={newEmail && accounts ? '........' : formik.values.password}
                        placeholder='Enter password'
                        disabled={newEmail && accounts}
                        sx={{
                          mt: 1,
                          width: '100%',
                          backgroundColor: 'white',
                          '& div': { pl: 0.3 },
                          '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                          '& div input': {
                            py: 1.5,
                            fontSize: '13px',
                            fontWeight: 400,
                            letterSpacing: '0em',
                            '&::placeholder': {
                              color: 'rgba(40, 40, 123, 0.5)'
                            }
                          },
                          '& .MuiFormHelperText-root': { textAlign: 'right', mx: 0 }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge='end'
                                aria-label='toggle password visibility'
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {' '}
                      <Typography
                        sx={{
                          width: '100%',
                          textAlign: 'left',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          color: '#101010',
                          mt: 2
                        }}
                      >
                        Confirm password *
                      </Typography>
                      <TextField
                        variant='outlined'
                        error={!!(formik.touched.password && formik.errors.confirmPassword)}
                        fullWidth
                        helperText={formik.touched.password && formik.errors.confirmPassword}
                        name='confirmPassword'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={newEmail && accounts ? '........' : formik.values.confirmPassword}
                        placeholder='Confirm password'
                        disabled={newEmail && accounts}
                        sx={{
                          mt: 1,
                          width: '100%',
                          backgroundColor: 'white',
                          '& div': { pl: 0.3 },
                          '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                          '& div input': {
                            py: 1.5,
                            fontSize: '13px',
                            fontWeight: 400,
                            letterSpacing: '0em',
                            '&::placeholder': {
                              color: 'rgba(40, 40, 123, 0.5)'
                            }
                          },
                          '& .MuiFormHelperText-root': { textAlign: 'right', mx: 0 }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                edge='end'
                                aria-label='toggle password visibility'
                              >
                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ paddingTop: '20px', display: 'flex', justifyContent: 'space-between' }}
                      xs={12}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                          name="terms"
                          checked={formik.values.terms}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          sx={{
                            padding: '0',
                            marginRight: '6px',
                            transform: 'scale(0.9)'
                          }}
                        />
                        <Typography
                          sx={{
                            color: '#101010',
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            display: 'inline'
                          }}
                        >
                          I have read the{' '}
                          <ButtonBase
                            variant='text'
                            onClick={() => setOpenTermsAndConditionsModal(true)}
                            sx={{
                              display: 'inline',
                              color: '#0071F6',
                              fontSize: '14px',
                              fontWeight: 700,
                              fontFamily: 'inherit',
                              verticalAlign: 'baseline'
                            }}
                          >
                            Terms and Conditions
                          </ButtonBase>
                          <TermsAndConditionsModal open={openTermsAndConditionsModal} onClose={() => setOpenTermsAndConditionsModal(false)} />
                          {' '}
                          and
                          {' '}
                          <ButtonBase
                            variant='text'
                            onClick={() => setOpenPrivacyPolicyModal(true)}
                            sx={{
                              display: 'inline',
                              color: '#0071F6',
                              fontSize: '14px',
                              fontWeight: 700,
                              fontFamily: 'inherit',
                              verticalAlign: 'baseline'
                            }}
                          >
                            Privacy Policy.
                          </ButtonBase>
                          <PrivacyPolicyModal open={openPrivacyPolicyModal} onClose={() => setOpenPrivacyPolicyModal(false)} />
                        </Typography>
                        {formik.touched.terms && formik.errors.terms && (
                          <Typography color="error" sx={{ fontSize: '14px',fontWeight:'400', mt: 1 }}>
                            {formik.errors.terms}
                          </Typography>
                        )}
                      </Box>
                    </Grid>

                    {sumo && (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            width: '100%',
                            textAlign: 'left',
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            color: '#28287B',
                            mt: 2
                          }}
                        >
                          AppSumo Code *
                        </Typography>
                        <TextField
                          variant='outlined'
                          disabled
                          error={!!(formik.touched.sumo && formik.errors.sumo)}
                          fullWidth
                          helperText={formik.touched.sumo && formik.errors.sumo}
                          name='sumo'
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.sumo}
                          placeholder='Enter Appsumo code...'
                          // ... (other input properties)
                        />
                      </Grid>
                    )}
                  </Grid>

                  {formik.errors.submit && (
                    <Typography color='error' sx={{ mt: 3, textAlign: 'right' }} variant='body2'>
                      {formik.errors.submit}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '20px'
                    }}
                  >
                    <Button
                      fullWidth
                      sx={{
                        mt: 2,
                        py: 2,
                        fontSize: '14px',
                        fontWeight: 700,
                        borderRadius: '14px'
                      }}
                      // disabled={!rememberMe}
                      type='submit'
                      variant='contained'
                    >
                      {formik.isSubmitting
                        ? (
                          <CircularProgress size={20} color='inherit' />
                          )
                        : (
                          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                            {newEmail && accounts ? 'Update' : 'Create an account'}
                          </Typography>
                          )}
                    </Button>
                  </Box>
                  <Box mt={2}>
                    <Typography
                      sx={{
                        color: '#101010',
                        fontSize: '14px',
                        fontWeight: 500,
                        display: 'inline'
                      }}
                    >
                      Already have an account?
                      <Link
                        onClick={againLogin}
                        sx={{
                          textDecoration: 'none',
                          '&:hover': {
                            color: '#164694'
                          },
                          ml: 0.3,
                          color: '#0071F6',
                          fontWeight: 700,
                          cursor: 'pointer'
                        }}
                      >
                        Sign In
                      </Link>
                    </Typography>
                  </Box>
                </form>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignSelf: 'center' }}>
              <Typography
                sx={{
                  color: '#8181B0',
                  fontSize: '13px',
                  fontWeight: 500,
                  lineHeight: '18px'
                }}
              >
                2024 Success.ai, All right Reserved
              </Typography>
            </Box>
          </Box>
        </Box>
        {isMdUp && (
          <Box
            sx={{
              background: '#3F4FF8',
              color: 'white',
              position: 'relative',
              // height: "100%",
              width: '50%',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <RegisterAnimation />
          </Box>
        )}
        {/* {isMdUp && (
          <Box
            sx={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "calc(100vw - 50%)",
              height: "100%",
              overflow: "hidden",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: -400,
                left: 250,
                width: 600,
                height: 800,

                background: `linear-gradient(to bottom,${alpha(
                  theme.palette.background.paper,
                  0.1
                )},transparent)`,
                rotate: "45deg",
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                bottom: -500,
                right: 250,
                width: 600,
                height: 800,

                background: `linear-gradient(to top,${alpha(
                  theme.palette.background.paper,
                  0.1
                )},transparent)`,
                rotate: "135deg",
              }}
            ></Box>
            <Box sx={{ zIndex: 99 }}>
              {" "}
              <img src={SignupImage} alt="signup page image" style={{ transform: "scale(0.8)" }} />
            </Box>
          </Box>
        )} */}
      </Box>
    </>
  )
}

export default RegisterPage
